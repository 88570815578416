import { React, useState } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import Checkbox, { CheckboxGroup } from '@iso/components/uielements/checkbox';
import Box from '@iso/components/utility/box';
import ContentHolder from '@iso/components/utility/contentHolder';
import Button from '@iso/components/uielements/button';
import { Alert, Input, Spin, Tooltip } from 'antd';
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { useContext } from 'react';
import { AppContext } from '../../../context/ContextProvider';
import styled, { createGlobalStyle } from 'styled-components';
import Select from '../../Deployments/Deployment.styles';
import {
  SelectOption as Option,
} from '@iso/components/uielements/select';
import initialData from '../../../redux/scrumBoard/data';
import { useEffect } from 'react';
import { Form, useNavigate } from 'react-router-dom';
import { borderRadius } from '../../../library/helpers/style_utils';
import {
  PlusOutlined
} from '@ant-design/icons';
import plusIcon from '../../../assets/images/plusIcon.svg';
import { Link } from 'react-router-dom';
import CreateTaskFooter from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter';
import deleteIcon from '../../../assets/images/tagsDelete.svg';
import { Switch } from 'antd';
import downIcon from '../../../assets/images/downArrow.svg';
import upIcon from '../../../assets/images/upArrow.svg';
import api from '../../../api';
import { InfoCircleFilled } from '@ant-design/icons';
import { ConvertUTCToLocal, ConvertUTCToLocalDate, customFilterOption, customSortOptions } from '../../../context/helper';
import CustomInfoTooltip from '../../Dashboard/CustomComponents/CustomInfoTooltip';
import { clusterHelpChecks } from '../clusterHelpChecks';


const ProdContentHold = styled.div`
  .isoBoxWrapper {
    height: auto;
    border: none;
    margin: 0;
  }
`;

const serverInsTypes = ["t3.small", "m4.large", "c5.large", "r3.large"];
serverInsTypes.map((child, ind) => {
  serverInsTypes.push(<Option key={child} name="serverInsType" value={child}>{child}</Option>);
});

var optionAzs = [];

let innerTitleStyle = {
  color: "#1f1f1f",
  fontFamily: "Poppins",
  fontType: "medium",
  fontWeight: "500",
  fontSize: "14px"
};

let innerSubtitleStyle = {
  fontSize: "12px",
  color: "#9b9b9b",
  fontFamily: "Nunito Sans",
  fontWeight: "400",
  fontType: "regular",
}

let subContentStyle = {
  fontFamily: "Nunito Sans",
  fontSize: "13px",
  fontWeight: "300",
  // text-align: left,
  color: "#afafaf",
}

const inputStyle = {
  height: "44px"
}

const MultiSelect = styled(Select)`
.ant-select-selector {
  min-height: 44px !important;
  height: auto !important;
}
  .ant-select-selection-overflow-item {
    width: 100%;
  }
  .ant-select-selection-item {
    width: 100%;
    height: 44px !important;
    min-height: 42px !important;
  }
  .ant-select-selection-item-content {
    width: 100%;
  }
  .ant-select-selection-item {
    background: #f5f5f5;
  }
`;

export default function ClusterProd({
  // handleSubmit,
  data,
  setData,
  values,
  submitCount,
  onCancel,
  onDelete,
  openDrawer,
  onEditCancel,
  tabSelected,
  openAddOns
}) {

  const { openRightDrawer, setOpenRightDrawer, allClusters, setAllClusters, currentCluster, updateCluster, setUpdateCluster, setCurrentCluster, isUpdate, isOneClick, oneClickCluster, setOneClickCluster, clusterTabSelected, fetchingClusterPrerequisites, setFetchingClusterPrerequisites } = useContext(AppContext);

  const [myClusterData, setMyClusterData] = useState(isUpdate ? updateCluster : isOneClick ? oneClickCluster : currentCluster);
  console.log("Begin myclusterdata: ", myClusterData);
  const [error, setError] = useState({
    status: false,
    msg: "Success",
    field: ""
  });
  const navigate = useNavigate();
  const [myVersions, setMyVersions] = useState([]);
  const [clusterLogRetentionOptions, setClusterLogRetentionOptions] = useState([]);
  const [vpcLogRetentionOptions, setVpcLogRetentionOptions] = useState([]);
  const [flowLogMaxOptions, setFlowLogMaxOptions] = useState([]);
  const [isAdvanced, setIsAdvanced] = useState(true);
  const [subnetList, setSubnetList] = useState({
    publicSubnets: [],
    privateSubnets: []
  });
  const [searchValue, setSearchValue] = useState("");
  const [showAlert, setShowAlert] = useState({
    publicSubnets: false,
    privateSubnets: false
  });

  // useEffect(() => {
  //   console.log("Current Cluster useEffect in cluster [tabSelected]: ", currentCluster);
  //   setMyClusterData(isUpdate ? updateCluster : isOneClick ? oneClickCluster : currentCluster);
  // }, [currentCluster, updateCluster, oneClickCluster]);

  useEffect(() => {
    if (myClusterData.vpc !== "Create New VPC") {
      let publicSubnets = [], privateSubnets = [];
      if(isUpdate && myClusterData.provisioner === 'imported'){
        myClusterData.existingVpc.subnets.forEach((val) => {
          publicSubnets.push(<Option  name="publicSubnets" value={val} disabled={true}>{val}</Option>)
          privateSubnets.push(<Option  name="privateSubnets" value={val} disabled={true}>{val}</Option>)
        })
      }
      else{
        myClusterData.existingVpc.subnets.forEach((val) => {
          publicSubnets.push(<Option name="publicSubnets" disabled={val.min_available_ips > val.available_ips} value={val.id}>
            <div style={{ display: "flex", justifyContent: "space-between" }} title={val.min_available_ips > val.available_ips ? `The available IPs for this subnet should be greater than the minimum threshold value ${val.min_available_ips}` : ""}>
              <div>
                <p>
                  <span>{val.name}</span>
                  <span style={{ fontWeight: "400" }}>&nbsp;({val.id})</span>
                </p>
                <p>{val.az}</p>
              </div>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <p>{val.subnet_cidr_block}</p>
              </div>
            </div>
          </Option>);
  
          privateSubnets.push(<Option name="privateSubnets" disabled={val.min_available_ips > val.available_ips} value={val.id}>
            <div style={{ display: "flex", justifyContent: "space-between" }} title={val.min_available_ips > val.available_ips ? `The available IPs for this subnet should be greater than the minimum threshold value ${val.min_available_ips}` : ""}>
              <div>
                <p>
                  <span>{val.name}</span>
                  <span style={{ fontWeight: "400" }}>&nbsp;({val.id})</span>
                </p>
                <p>{val.az}</p>
              </div>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <p>{val.subnet_cidr_block}</p>
              </div>
            </div>
          </Option>);
        });
      }
      
      setSubnetList({ publicSubnets: publicSubnets, privateSubnets: privateSubnets });
    }
  }, []);

  useEffect(() => {
    let arr = [];
    let clusterLog = [];
    let vpcLog = [];
    let flowLogMaxList = [];
    if (isUpdate) {
      let childVerInd = -2;
      updateCluster[tabSelected].versionsAvailable.map((child, ind) => {
        if (child.version === updateCluster.selectedCluster.cluster_data.kubernetes_version) {
          childVerInd = ind;
          arr.push(<Option key={child.version} name="eks" value={child.version}>
            <div style={{ width: "98%" }}>
              <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <span>{child.version}</span><span>current</span>
              </div>
              <p style={subContentStyle}>{`End of Extended Support : `}<b> {child.end_of_extended_support_time ? ConvertUTCToLocalDate(child.end_of_extended_support_time) : "N/A"}</b></p>
              <p style={subContentStyle}>{`End of Standard Support : `}<b> {child.end_of_standard_support_time ? ConvertUTCToLocalDate(child.end_of_standard_support_time) : "N/A"}</b></p>
            </div>
          </Option>);
        }
        else {
          arr.push(<Option key={child.version} disabled={(childVerInd + 1) !== ind} name="eks" value={child.version}>
            <div>
              <p style={{}}>{child.version}</p>
              <p style={subContentStyle}>{`End of Extended Support : `}<b> {child.end_of_extended_support_time ? ConvertUTCToLocalDate(child.end_of_extended_support_time) : "N/A"}</b></p>
              <p style={subContentStyle}>{`End of Standard Support : `}<b> {child.end_of_standard_support_time ? ConvertUTCToLocalDate(child.end_of_standard_support_time) : "N/A"}</b></p>
            </div>
          </Option>);
        }
      });
    }
    else {
      console.log("Entering versions: ",);
      let selectedCluster = isOneClick ? oneClickCluster : currentCluster;
      selectedCluster[tabSelected].versionsAvailable.map((child, ind) => {
        arr.push(<Option key={child.version} name="eks" value={child.version}>
          <div>
            <p style={{}}>{child.version}</p>
            <p style={subContentStyle}>{`End of Extended Support : `}<b> {child.end_of_extended_support_time ? ConvertUTCToLocalDate(child.end_of_extended_support_time) : "N/A"}</b></p>
            <p style={subContentStyle}>{`End of Standard Support : `}<b> {child.end_of_standard_support_time ? ConvertUTCToLocalDate(child.end_of_standard_support_time) : "N/A"}</b></p>
          </div>
        </Option>);
      });
    }

    initialData.retentionPeriodInDays.map((child, ind) => {
      clusterLog.push(<Option key={child} name="clusterLogRetention" value={child}>{child}</Option>);
    });

    initialData.retentionPeriodInDays.map((child, ind) => {
      vpcLog.push(<Option key={child} name="logRetention" value={child}>{child}</Option>);
    });

    initialData.flowLogMaxInSec.map((child, ind) => {
      flowLogMaxList.push(<Option key={child} name="flowLogMax" value={child}>{child}</Option>);
    });

    setMyVersions(arr);
    setClusterLogRetentionOptions(clusterLog);
    setVpcLogRetentionOptions(vpcLog);
    setFlowLogMaxOptions(flowLogMaxList);
    setMyClusterData(isUpdate ? updateCluster : isOneClick ? oneClickCluster : currentCluster);
  }, []);

  useEffect(() => {
    if ((isUpdate && updateCluster[tabSelected].versionsAvailable.length > 0 && oneClickCluster[tabSelected].eks !== "") || (isOneClick && oneClickCluster[tabSelected].versionsAvailable.length > 0 && oneClickCluster[tabSelected].eks !== "")) {
      let selectedCluster = isUpdate ? updateCluster : oneClickCluster;
      let result = selectedCluster[tabSelected].versionsAvailable.find((val) => {
        console.log("Value: ", selectedCluster[tabSelected].eks);
        if (val.version === selectedCluster[tabSelected].eks) {
          return val;
        }
      });
      fetchAddons(result);
    }
  }, []);

  useEffect(() => {
    if (myClusterData.existingVpc.publicSubnets.length === 0 && myClusterData.existingVpc.privateSubnets.length > 0) {
      setShowAlert(showAlert => ({
        ...showAlert,
        publicSubnets: true
      }));
    }
    if (myClusterData.existingVpc.privateSubnets.length === 0 && myClusterData.existingVpc.publicSubnets.length > 0) {
      setShowAlert(showAlert => ({
        ...showAlert,
        privateSubnets: true
      }));
    }
  }, []);


  let azs = isUpdate ? (updateCluster.availability_zones ? updateCluster.availability_zones : []) : isOneClick ? (oneClickCluster.availability_zones ? oneClickCluster.availability_zones : []) : (currentCluster.availability_zones ? currentCluster.availability_zones : []);
  optionAzs = [];
  azs.map((child, ind) => {
    optionAzs.push(<Option key={child + "-" + ind} name="naz" value={child} disabled={(isUpdate && updateCluster.selectedCluster.cluster_data.availability_zones.includes(child))}>{child}</Option>);
  });

  // const handleChange = value => {
  //   console.log('value.target.name: ', value.target.name);
  //   console.log('value.target.checked: ', value.target.checked);
  //   myClusterData[tabSelected][value.target.name] = value.target.checked;
  //   setData({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
  //   setMyClusterData({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
  //   setError({
  //     status: false,
  //     msg: ""
  //   });
  // };

  const fetchAddons = (result) => {
    api.get(process.env.REACT_APP_API_URI + `/api/cluster/cluster-addons/${isUpdate ? updateCluster.integration : isOneClick ? oneClickCluster.integration : currentCluster.integration}/${result.id}/${isUpdate ? "?cluster_id="+ updateCluster.id +'/': ''}`)
      .then(async (res) => {
        let resData = await res.data;
        if (resData.data) {
          myClusterData[tabSelected]["addonsData"] = resData;
          if (myClusterData[tabSelected]["selectedAddons"].length > 0 && isUpdate) {
            myClusterData[tabSelected]["addonsData"].data.forEach((val) => {
              let ele = myClusterData[tabSelected]["selectedAddons"].data.includes(x => x.id === val.addon_id);
              if (!ele) {
                myClusterData[tabSelected]["selectedAddons"].push({
                  id: val.addon_id,
                  name: val.display_name,
                  dependsOn: val.depends_on,
                  selected: false,
                  disabled: false,
                  recommended: val.recommended,
                  editable: true,
                  subText: val.info,
                  borderColor: "#f5f5f5",
                  message: ""
                });
              }
            });
          }
          // else {
          //   myClusterData[tabSelected]["addonsData"] = { data: {} };
          // }
          if (!isUpdate && !isOneClick) {
            setCurrentCluster({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
          }
          else if (isUpdate) {
            setUpdateCluster({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
          }
          else {
            setOneClickCluster({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] })
          }
          setData({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
          setMyClusterData({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
        }
        console.log("This is the addons data: ", resData);
      })
      .catch((err) => {
        console.log("Something went wrong in cluster-addons api :", err);
        // myClusterData[tabSelected]["addonsData"] = { data: {} };
        if (isUpdate) {
          setUpdateCluster({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
        }
        else if (isOneClick) {
          setOneClickCluster({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
        }
        else {
          setCurrentCluster({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
        }
        setData({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
        setMyClusterData({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
      });
  }

  const handleSelectChange = (value, event) => {
    // console.log('updated myClusterData :', myClusterData);
    let result = isUpdate ? updateCluster[tabSelected].versionsAvailable.find((val) => {
      if (val.version === value) {
        return val;
      }
    }) : isOneClick ?
      oneClickCluster[tabSelected].versionsAvailable.find((val) => {
        if (val.version === value) {
          return val;
        }
      }) : currentCluster[tabSelected].versionsAvailable.find((val) => {
        if (val.version === value) {
          return val;
        }
      })

    fetchAddons(result);

    myClusterData[tabSelected][event.name] = value;
    setData({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
    setMyClusterData({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
    setError({
      status: false,
      msg: ""
    });
    if (isUpdate) {
      setUpdateCluster({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
    }
    else if (isOneClick) {
      setOneClickCluster({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
    }
    else {
      setCurrentCluster({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
    }
  };

  const handleAdvanceSelectChange = (value, event) => {
    myClusterData[tabSelected][event.name] = value;
    console.log('updated myClusterData :', myClusterData);
    setError({
      status: false,
      msg: ""
    });
    setData({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
    setMyClusterData({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
  }

  const handleMultiSelectChange = (value, event, fieldName) => {
    console.log('event :', event, fieldName);
    console.log('value :', value);
    if (fieldName === "naz") {
      if (value.length > myClusterData.prerequisiteInfo.available_eip_quota) {
        console.log("Azs Error");
        setError({
          status: true,
          msg: `You can select upto ${myClusterData.prerequisiteInfo.available_eip_quota} availability zones.`
        });
      }
      else {
        console.log('ok');
        setError({
          status: false,
          msg: ""
        })
      }
      myClusterData[tabSelected].naz = value;
      if (isUpdate) {
        setData({ ...updateCluster, [tabSelected]: myClusterData[tabSelected] });
        setMyClusterData({ ...updateCluster, [tabSelected]: myClusterData[tabSelected] });
      }
      else if (isOneClick) {
        setData({ ...oneClickCluster, [tabSelected]: myClusterData[tabSelected] });
        setMyClusterData({ ...oneClickCluster, [tabSelected]: myClusterData[tabSelected] });
      }
      else {
        setData({ ...currentCluster, [tabSelected]: myClusterData[tabSelected] });
        setMyClusterData({ ...currentCluster, [tabSelected]: myClusterData[tabSelected] });
      }
    }
    else if (fieldName === "publicSubnets") {
      let selectedVals = [];
      value.forEach(x => {
        selectedVals.push(myClusterData[tabSelected].subnets.find(val => val.id === x));
      });
      console.log("Selected vals: ", selectedVals);
      myClusterData[tabSelected].publicSubnets = selectedVals;

      if (selectedVals.some(val => val.type === "Private")) {
        setError({
          status: true,
          msg: "Invalid subnet selection: A private subnet cannot be used for the public subnet field.",
          field: "publicSubnets"
        });
      }
      else {
        setError({
          status: false,
          msg: "",
          field: ""
        });
      }
      if (isUpdate) {
        setData({ ...updateCluster, [tabSelected]: myClusterData[tabSelected] });
        setMyClusterData({ ...updateCluster, [tabSelected]: myClusterData[tabSelected] });
      }
      else if (isOneClick) {
        setData({ ...oneClickCluster, [tabSelected]: myClusterData[tabSelected] });
        setMyClusterData({ ...oneClickCluster, [tabSelected]: myClusterData[tabSelected] });
      }
      else {
        setData({ ...currentCluster, [tabSelected]: myClusterData[tabSelected] });
        setMyClusterData({ ...currentCluster, [tabSelected]: myClusterData[tabSelected] });
      }
      setShowAlert(showAlert => ({
        ...showAlert,
        privateSubnets: false,
        publicSubnets: false
      }));
    }
    else {
      let selectedVals = [];
      value.forEach(x => {
        selectedVals.push(myClusterData[tabSelected].subnets.find(val => val.id === x));
      });
      myClusterData[tabSelected].privateSubnets = selectedVals;
      if (selectedVals.some(val => val.type === "Public")) {
        setError({
          status: true,
          msg: "Invalid subnet selection: A public subnet cannot be used for the private subnet field.",
          field: "privateSubnets"
        });
      }
      else {
        setError({
          status: false,
          msg: "",
          field: ""
        });
      }
      if (isUpdate) {
        setData({ ...updateCluster, [tabSelected]: myClusterData[tabSelected] });
        setMyClusterData({ ...updateCluster, [tabSelected]: myClusterData[tabSelected] });
      }
      else if (isOneClick) {
        setData({ ...oneClickCluster, [tabSelected]: myClusterData[tabSelected] });
        setMyClusterData({ ...oneClickCluster, [tabSelected]: myClusterData[tabSelected] });
      }
      else {
        setData({ ...currentCluster, [tabSelected]: myClusterData[tabSelected] });
        setMyClusterData({ ...currentCluster, [tabSelected]: myClusterData[tabSelected] });
      }
      setShowAlert(showAlert => ({
        ...showAlert,
        privateSubnets: false,
        publicSubnets: false
      }));
    }
  };

  const handelInputChange = (e) => {
    let val = e.target.value.replace(/\s/g, '');
    if (e.target.name === "name" || e.target.name === "value") {
      // console
      myClusterData[tabSelected].tags[e.target.id][e.target.name] = val;
    }
    else if (["logRetention", "clusterLogRetention", 'flowLogMax'].includes(e.target.name)) {
      //const logRetentionValue = e.target.value.replace(/\D/g, '');
      myClusterData[tabSelected][e.target.name] = e.target.value;
    }
    else {
      myClusterData[tabSelected][e.target.name] = val;
    }

    console.log('updated myClusterData :', myClusterData);
    setError({
      status: false,
      msg: ""
    });
    setData({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
    setMyClusterData({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
  };

  /*const handelBlurEvent = (e) => {
    if(e.target.name === "vpcCidr"){
      // console
      if(e.target.value.match(/\b(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([0-9]|[1-2][0-9]|3[0-2])\b/)){
        console.log('True');
        setError({
          status: false,
          msg: ""
        });
      }
      else{
        console.log('False');
        setError({
          status: true,
          msg: "Please provide correct vpc cidr(example: 10.0.0.0/16)"
        });
      }
      myClusterData[tabSelected][e.target.name] = e.target.value;
    }
    // else if(e.target.name === "naz"){
    //   if(myClusterData[tabSelected].naz.length < 2){
    //     console.log('naz length error');
    //   }
    //   else{
    //     console.log('naz length ok');
    //   }
    // }
  }*/

  const filterClusterLogOption = (input, option) => (option?.value.toLowerCase() ?? '').includes(input.toLowerCase());

  const filterVPCLogOption = (input, option) => (option?.value.toLowerCase() ?? '').includes(input.toLowerCase());

  const handleSubmit = () => {
    let selectedCluster = isUpdate ? updateCluster : isOneClick ? oneClickCluster : currentCluster;
    // console.log("Checking: ", selectedCluster);
    // if (selectedCluster[tabSelected].publicSubnets.some(val => val.type === "Private")) {
    //   setError({
    //     status: true,
    //     msg: "Invalid subnet selection: A private subnet cannot be used for the public subnet field.",
    //     field: "publicSubnets"
    //   });
    // }
    // else if (selectedCluster[tabSelected].privateSubnets.some(val => val.type === "Public")) {
    //   setError({
    //     status: true,
    //     msg: "Invalid subnet selection: A public subnet cannot be used for the private subnet field.",
    //     field: "privateSubnets"
    //   });
    // }
    // else {
    openAddOns(selectedCluster, clusterTabSelected);
    // }
  }

  const handleFocus = (e) => {
    if (myClusterData.existingVpc.publicSubnets.length === 0 && myClusterData.existingVpc.privateSubnets.length > 0) {
      setShowAlert(showAlert => ({
        ...showAlert,
        publicSubnets: true
      }));
    }
    if (myClusterData.existingVpc.privateSubnets.length === 0 && myClusterData.existingVpc.publicSubnets.length > 0) {
      setShowAlert(showAlert => ({
        ...showAlert,
        privateSubnets: true
      }));
    }
  }


  return (
    <ProdContentHold>
      <CreateTaskHeader
        buttonContent="Cluster"
        data={myClusterData}
        // values={{editing: true}}
        onCancel={() => {
          setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
              myClusterData: myClusterData,
              tabSelected: tabSelected,
            },
          });
        }}
        onDelete={onDelete}
        onEditCancel={() => {
          setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
              myClusterData: myClusterData,
              tabSelected: tabSelected,
            },
          });
        }}
        openBasicView={() => {
          setOpenRightDrawer({
            drawerType: tabSelected,
            drawerProps: {
              data: myClusterData,
              setData: setMyClusterData,
              tabSelected: tabSelected,
            },
          });
        }}
        openCodeView={() => {
          setOpenRightDrawer({
            drawerType: "prodCodeView",
            drawerProps: {
              data: myClusterData,
              setData: setMyClusterData,
              tabSelected: tabSelected,
            },
          });
        }}
      />
      <div style={{ marginTop: "80px" }}>
        {myClusterData.vpc === "Create New VPC" && <Box
          title={myClusterData.integration === 1 ? <div style={{ display: "flex" }}><IntlMessages id="cluster.noofavailablityzones.title" />* {/*isUpdate && <Tooltip placement='bottom' title="You have the option to add an availability zone, but the existing zone selections cannot be modified."><InfoCircleFilled style={{ marginLeft: "10px" }} /></Tooltip>*/}</div> : <p> <IntlMessages id="cluster.availabilityzones.title" />*</p>}
          subtitle={<IntlMessages id="cluster.noofavailablityzones.subTitle" />}
          titleStyle={innerTitleStyle}
          subTitleStyle={innerSubtitleStyle}
          style={{ marginTop: "80px" }}
        >
          <ContentHolder>
            <Select
              mode="multiple"
              name="naz"
              id="naz"
              style={{ width: '100%' }}
              placeholder={myClusterData.integration === 1 ? "Select availablity zones" : "Select Zones"}
              defaultValue={myClusterData[tabSelected].naz}
              onChange={(value, event) => {
                handleMultiSelectChange(value, event, "naz");
              }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              disabled={isUpdate}
              notFoundContent={fetchingClusterPrerequisites ? <Spin size="small" /> : null}
            >
              {
                // fetchingClusterPrerequisites ?
                //   <Option disabled>
                //     <div style={{ width: "inherit", display: "flex", justifyContent: "center", alignItems: "center" }}>
                //       <Spin size="small" />
                //     </div>
                //   </Option>
                //   :
                optionAzs
              }
            </Select>
            {(myClusterData[tabSelected].naz.length === 1 && !isUpdate) && <Alert message={`Atmosly adds an additional AZ for the control plane, keeping nodes in a single AZ.`} type="info" style={{ marginTop: "20px", color: "#4D99EF", fontSize: "14px", borderRadius: "10px", backgroundColor: "#e3f0fd", borderColor: "transparent" }} />}
          </ContentHolder>
          {error.status && <p className="isoHelperText">
            <span>{error.msg}</span>
          </p>}
        </Box>}

        {myClusterData.vpc !== "Create New VPC" && <>
          <Box
            title={myClusterData.integration === 1 ? <div style={{ display: "flex" }}>
              <IntlMessages id="cluster.publicSubnets.title" />
            </div> : <p> <IntlMessages id="cluster.availabilityzones.title" />*</p>}
            subtitle={<IntlMessages id="cluster.publicSubnets.subTitle" />}
            titleStyle={innerTitleStyle}
            subTitleStyle={innerSubtitleStyle}
          >
            <ContentHolder>
              <MultiSelect
                mode="multiple"
                showSearch
                name="publicSubnets"
                style={{ width: '100%', minHeight: "44px", marginBottom: "10px" }}
                placeholder={"Select Public Subnet"}
                defaultValue={isUpdate && myClusterData.provisioner === 'imported'? myClusterData.existingVpc.publicSubnets.map((val) => val):myClusterData.existingVpc.publicSubnets.map((val) => val.id)}
                onChange={(value, event) => {
                  handleMultiSelectChange(value, event, "publicSubnets");
                }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                disabled={isUpdate}
                notFoundContent={fetchingClusterPrerequisites ? <Spin size="small" /> : null}
              >
                {
                  // fetchingClusterPrerequisites ?
                  //   <Option disabled>
                  //     <div style={{ width: "inherit", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  //       <Spin size="small" />
                  //     </div>
                  //   </Option>
                  //   :
                  subnetList.publicSubnets
                }
              </MultiSelect>
              {showAlert.publicSubnets && myClusterData.existingVpc.publicSubnets.length === 0 && myClusterData.existingVpc.privateSubnets.length > 0 && <Alert type='warning' showIcon message="You have not selected a public subnet." description="PLEASE NOTE: Public subnet is needed for loadbalancer. The loadbalancer would then be deployed privately and will not be accessible over the internet." />}
            </ContentHolder>
            {error.status && error.field === "publicSubnets" && <p className="isoHelperText">
              <span>{error.msg}</span>
            </p>}
          </Box>

          <Box
            title={myClusterData.integration === 1 ? <div style={{ display: "flex" }}><IntlMessages id="cluster.privateSubnets.title" /> {/*isUpdate && <Tooltip placement='bottom' title="You have the option to add an availability zone, but the existing zone selections cannot be modified."><InfoCircleFilled style={{ marginLeft: "10px" }} /></Tooltip>*/}</div> : <p> <IntlMessages id="cluster.availabilityzones.title" />*</p>}
            subtitle={<IntlMessages id="cluster.privateSubnets.subTitle" />}
            titleStyle={innerTitleStyle}
            subTitleStyle={innerSubtitleStyle}
          >
            <ContentHolder>
              <MultiSelect
                mode="multiple"
                showSearch
                name="privateSubnets"
                style={{ width: '100%', minHeight: "44px", marginBottom: "10px" }}
                placeholder={"Select Private Subnet"}
                defaultValue={isUpdate && myClusterData.provisioner === 'imported'? myClusterData.existingVpc.privateSubnets.map((val) => val):myClusterData.existingVpc.privateSubnets.map((val) => val.id)}
                onChange={(value, event) => {
                  handleMultiSelectChange(value, event, "privateSubnets");
                }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                disabled={isUpdate}
                notFoundContent={fetchingClusterPrerequisites ? <Spin size="small" /> : null}
              >
                {
                  // fetchingClusterPrerequisites ?
                  //   <Option disabled>
                  //     <div style={{ width: "inherit", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  //       <Spin size="small" />
                  //     </div>
                  //   </Option>
                  //   :
                  subnetList.privateSubnets
                }
              </MultiSelect>
              {(showAlert.privateSubnets && myClusterData.existingVpc.privateSubnets.length === 0 && myClusterData.existingVpc.publicSubnets.length > 0) && <Alert type='warning' showIcon message="You have not selected a private subnet." description="PLEASE NOTE: Private subnet is needed for worker nodes. Click on Save & Next to deploy worker nodes in public subnet." />}
            </ContentHolder>
            {error.status && error.field === "privateSubnets" && <p className="isoHelperText">
              <span>{error.msg}</span>
            </p>}
          </Box>
        </>}
      </div>

      {/* <hr style={{ width: "92%", borderTop: "1px solid #88898b24", marginTop: "15px", marginBottom: "15px" }} /> */}

      <Box
        title={<div style={{ display: "flex", justifyContent: "space-between" }}>
          <p><IntlMessages id="cluster.eksversion.title" />*</p>
          <CustomInfoTooltip title={clusterHelpChecks.cluster.kubernetesVersion} placement={"bottomLeft"} />
        </div>}
        subtitle={<IntlMessages id="cluster.eksversion.subTitle" />}
        titleStyle={innerTitleStyle}
        subTitleStyle={innerSubtitleStyle}
      >
        {console.log("Cluster data before 740: ", myClusterData, clusterTabSelected)}
        <ContentHolder>
          <Select
            defaultValue={myClusterData[tabSelected].eks}
            className="customVersions"
            id="version"
            onChange={handleSelectChange}
            style={{ width: "100%" }}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            onFocus={handleFocus}
            optionLabelProp={myClusterData[tabSelected].eks}
          >
            {myVersions}
          </Select>
        </ContentHolder>
      </Box>

      {myClusterData.vpc === "Create New VPC" && <Box
        title={<div style={{ display: "flex", justifyContent: "space-between" }}>
          <p><IntlMessages id="cluster.vpccidr.title" />*</p>
          <CustomInfoTooltip title={clusterHelpChecks.cluster.vpcCidr} placement={"bottomLeft"} />
        </div>}
        subtitle={<IntlMessages id="cluster.vpccidr.subTitle" />}
        titleStyle={innerTitleStyle}
        subTitleStyle={innerSubtitleStyle}
      >
        <ContentHolder>
          <Input
            name="vpcCidr"
            value={myClusterData[tabSelected].vpcCidr}
            style={{ ...inputStyle }}
            onChange={handelInputChange}
            //onBlur={handelBlurEvent}
            onKeyDown={(e) => {
              console.log("event.key :", e.key);
              if (e.key === "ArrowRight" && myClusterData[tabSelected].vpcCidr === "") {
                myClusterData[tabSelected][e.target.name] = "10.0.0.0/16";
                setData({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
                setMyClusterData({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
              }
            }}
            placeholder="10.0.0.0/16"
            maxLength="19"
          />
          {/* {
            isUpdate && <div style={{ display: "flex", marginTop: "20px", cursor: "pointer", width: "fit-content" }}>
              <img src={plusIcon} style={{ width: "15px", height: "15px", marginRight: "10px" }} />
              <p>Add secondary range</p>
            </div>
          } */}
        </ContentHolder >
      </Box >}

      <Box
        title={<p>{myClusterData.integration === 1 ? <IntlMessages id="cluster.tags.title" /> : <IntlMessages id="cluster.labels.title" />}</p>}
        titleStyle={innerTitleStyle}
      >
        {myClusterData[tabSelected].tags.map((tag, ind) => (
          <ContentHolder key={"tag-" + ind} style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
            <Input
              name="name"
              id={ind}
              value={myClusterData[tabSelected].tags[ind] && myClusterData[tabSelected].tags[ind]['name']}
              onChange={handelInputChange}
              placeholder={myClusterData.integration === 1 ? "Tag Name" : "Name"}
              style={{ height: "44px", margin: "0 10px" }}
              onFocus={handleFocus}
            />
            <Input
              name="value"
              id={ind}
              value={myClusterData[tabSelected].tags[ind] && myClusterData[tabSelected].tags[ind]['value']}
              onChange={handelInputChange}
              placeholder={myClusterData.integration === 1 ? "Tag Value" : "Value"}
              style={{ height: "44px", margin: "0 10px" }}
              onFocus={handleFocus}
            />
            {ind === 0 && <Link onClick={(e) => {
              console.log('updated myClusterData :', myClusterData);
              myClusterData[tabSelected].tags.push({
                name: "",
                value: ""
              });
              setData({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
              setMyClusterData({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
            }}
              style={{ marginRight: "10px" }}
            > <img src={plusIcon} /> </Link>}
            {ind !== 0 && <Link id={ind} onClick={(e) => {
              console.log('Id to be deleted :', e.currentTarget.id);
              myClusterData[tabSelected].tags.splice(e.currentTarget.id, 1)
              setData({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
              setMyClusterData({ ...myClusterData, [tabSelected]: myClusterData[tabSelected] });
            }}>
              <img src={deleteIcon} style={{ marginRight: "10px" }} />
            </Link>}
          </ContentHolder>
        ))}
      </Box>

      <Box
        title={<p><IntlMessages id="cluster.vpcflowlogs.title" />*</p>}
        subtitle={<IntlMessages id="cluster.vpcflowlogs.subTitle" />}
        titleStyle={innerTitleStyle}
        subTitleStyle={innerSubtitleStyle}
        style={{ display: "flex", justifyContent: "space-between", pointerEvents: clusterTabSelected === "prod" && "none", opacity: clusterTabSelected === "prod" && "0.7" }}
      >
        <Switch
          style={myClusterData[tabSelected].vpcFlowLogs ? { backgroundColor: "#132f44" } : {}}
          disabled={isUpdate}
          checked={myClusterData[tabSelected].vpcFlowLogs}
          onFocus={handleFocus}
          onChange={(checked) => {
            setData(data => ({
              ...data,
              [tabSelected]: {
                ...data[tabSelected],
                vpcFlowLogs: checked
              }
            }))
            setMyClusterData(myClusterData => ({
              ...myClusterData,
              [tabSelected]: {
                ...myClusterData[tabSelected],
                vpcFlowLogs: checked
              }
            }));
            setError({
              status: false,
              msg: ""
            });
          }} />
      </Box>

      {
        myClusterData.integration === 1 && myClusterData[tabSelected].vpcFlowLogs && <>
          <p style={{ ...innerTitleStyle, fontWeight: "600", margin: "40px 20px 24px 20px", cursor: "pointer" }} onClick={() => {
            setIsAdvanced(isAdvanced => !isAdvanced);
          }}> Advanced Settings <img src={(isAdvanced || isUpdate) ? upIcon : downIcon} /> </p>

          {(isAdvanced || isUpdate) && <div>
            <Alert message="ATTENTION! Opting for Observability could elevate your cloud expense. Please ensure to assess the cost implications carefully." type="error" style={{ margin: "0 20px", color: "#d20a0a", fontSize: "14px", borderRadius: "10px", backgroundColor: "#f1dddd", borderColor: "transparent" }} />
            <Box
              title={<div style={{ display: "flex", justifyContent: "space-between" }}>
                <p><IntlMessages id="cluster.kubernetiveslogretentionperiod.title" />*</p>
                <CustomInfoTooltip title={clusterHelpChecks.cluster.logRetention} placement={"bottomLeft"} />
              </div>}
              subtitle={<IntlMessages id="cluster.kubernetiveslogretentionperiod.subTitle" />}
              titleStyle={innerTitleStyle}
              subTitleStyle={innerSubtitleStyle}
            >
              <ContentHolder>
                <Select
                  showSearch
                  defaultValue={myClusterData[tabSelected].clusterLogRetention}
                  onChange={handleAdvanceSelectChange}
                  style={{ width: "100%" }}
                  // filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                  filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function
                  onSearch={(value) => {
                    console.log("Enters search", value);
                    setSearchValue(value);
                  }}
                  onFocus={handleFocus}
                  //filterOption={filterClusterLogOption}
                  //disabled={isUpdate}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {clusterLogRetentionOptions}
                </Select>
              </ContentHolder>
            </Box>
            {clusterTabSelected === "prod" && <Box
              title={<div style={{ display: "flex", justifyContent: "space-between" }}>
                <p><IntlMessages id="cluster.logretentionperiod.title" />*</p>
                <CustomInfoTooltip title={clusterHelpChecks.cluster.vpcLogRetention} placement={"bottomLeft"} />
              </div>}
              subtitle={<IntlMessages id="cluster.logretentionperiod.subTitle" />}
              titleStyle={innerTitleStyle}
              subTitleStyle={innerSubtitleStyle}
            >
              <ContentHolder>
                <Select
                  showSearch
                  defaultValue={myClusterData[tabSelected].logRetention}
                  onChange={handleAdvanceSelectChange}
                  style={{ width: "100%" }}
                  // filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                  filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function
                  onSearch={(value) => {
                    console.log("Enters search", value);
                    setSearchValue(value);
                  }}
                  //filterOption={filterVPCLogOption}
                  //disabled={isUpdate}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {vpcLogRetentionOptions}
                </Select>
              </ContentHolder>
            </Box>}
            {clusterTabSelected === "prod" && <Box
              title={<div style={{ display: "flex", justifyContent: "space-between" }}>
                <p><IntlMessages id="cluster.flowlogmaxaggregationinterval.title" />* {/*<Tooltip title="Can be increased but not decreased"><InfoCircleFilled style={{ marginLeft: "10px" }} /></Tooltip>*/}</p>
                <CustomInfoTooltip title={clusterHelpChecks.cluster.vpcMaxAggregation} placement={"bottomLeft"} />
              </div>}
              subtitle={<IntlMessages id="cluster.flowlogmaxaggregationinterval.subTitle" />}
              titleStyle={innerTitleStyle}
              subTitleStyle={innerSubtitleStyle}
            >
              <ContentHolder>
                <Select
                  value={myClusterData[tabSelected].flowLogMax}
                  onChange={handleAdvanceSelectChange}
                  style={{ width: "100%" }}
                  disabled={isUpdate}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {flowLogMaxOptions}
                </Select>
              </ContentHolder>
            </Box>}

            <Box
              title={<p><IntlMessages id="cluster.controlPlaneLogging.title" />*</p>}
              subtitle={<IntlMessages id="cluster.controlPlaneLogging.subTitle" />}
              titleStyle={{ ...innerTitleStyle, fontSize: "16px" }}
              subTitleStyle={{ ...innerSubtitleStyle, fontSize: "14px" }}
              style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Box
                  title={<p><IntlMessages id="cluster.apiServer.title" /></p>}
                  subtitle={<IntlMessages id="cluster.apiServer.subTitle" />}
                  titleStyle={innerTitleStyle}
                  subTitleStyle={innerSubtitleStyle}
                  style={{ display: "flex", justifyContent: "space-between", padding: "40px 0 20px 0" }}
                />
                <Tooltip title={isUpdate ? "This field cannot be updated." : ""} placement='topLeft'>
                  <Switch style={myClusterData[tabSelected].apiServer ? { backgroundColor: "#132f44" } : {}}
                    checked={myClusterData[tabSelected].apiServer}
                    onFocus={handleFocus}
                    disabled={isUpdate}
                    onChange={(checked) => {
                      setData(data => ({
                        ...data,
                        [tabSelected]: {
                          ...data[tabSelected],
                          apiServer: checked
                        }
                      }))
                      setMyClusterData(myClusterData => ({
                        ...myClusterData,
                        [tabSelected]: {
                          ...myClusterData[tabSelected],
                          apiServer: checked
                        }
                      }));
                      setError({
                        status: false,
                        msg: ""
                      });
                    }} />
                </Tooltip>
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <Box
                  title={<p><IntlMessages id="cluster.audit.title" /></p>}
                  subtitle={<IntlMessages id="cluster.audit.subTitle" />}
                  titleStyle={innerTitleStyle}
                  subTitleStyle={innerSubtitleStyle}
                  style={{ display: "flex", justifyContent: "space-between", padding: "20px 0" }}
                />
                <Tooltip title={isUpdate ? "This field cannot be updated." : ""} placement='topLeft'>

                  <Switch style={myClusterData[tabSelected].audit ? { backgroundColor: "#132f44" } : {}}
                    disabled={isUpdate}
                    checked={myClusterData[tabSelected].audit}
                    onFocus={handleFocus}
                    onChange={(checked) => {
                      setData(data => ({
                        ...data,
                        [tabSelected]: {
                          ...data[tabSelected],
                          audit: checked
                        }
                      }))
                      setMyClusterData(myClusterData => ({
                        ...myClusterData,
                        [tabSelected]: {
                          ...myClusterData[tabSelected],
                          audit: checked
                        }
                      }));
                      setError({
                        status: false,
                        msg: ""
                      });
                    }} />
                </Tooltip>
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <Box
                  title={<p><IntlMessages id="cluster.authenticator.title" /></p>}
                  subtitle={<IntlMessages id="cluster.authenticator.subTitle" />}
                  titleStyle={innerTitleStyle}
                  subTitleStyle={innerSubtitleStyle}
                  style={{ display: "flex", justifyContent: "space-between", padding: "20px 0" }}
                />
                <Tooltip title={isUpdate ? "This field cannot be updated." : ""} placement='topLeft'>

                  <Switch style={myClusterData[tabSelected].authenticator ? { backgroundColor: "#132f44" } : {}}
                    checked={myClusterData[tabSelected].authenticator}
                    disabled={isUpdate}
                    onFocus={handleFocus}
                    onChange={(checked) => {
                      setData(data => ({
                        ...data,
                        [tabSelected]: {
                          ...data[tabSelected],
                          authenticator: checked
                        }
                      }))
                      setMyClusterData(myClusterData => ({
                        ...myClusterData,
                        [tabSelected]: {
                          ...myClusterData[tabSelected],
                          authenticator: checked
                        }
                      }));
                      setError({
                        status: false,
                        msg: ""
                      });
                    }} />
                </Tooltip>
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <Box
                  title={<p><IntlMessages id="cluster.controllerManager.title" /></p>}
                  subtitle={<IntlMessages id="cluster.controllerManager.subTitle" />}
                  titleStyle={innerTitleStyle}
                  subTitleStyle={innerSubtitleStyle}
                  style={{ display: "flex", justifyContent: "space-between", padding: "20px 0" }}
                />
                <Tooltip title={isUpdate ? "This field cannot be updated." : ""} placement='topLeft'>

                  <Switch style={myClusterData[tabSelected].controllerManager ? { backgroundColor: "#132f44" } : {}}
                    checked={myClusterData[tabSelected].controllerManager}
                    disabled={isUpdate}
                    onFocus={handleFocus}
                    onChange={(checked) => {
                      setData(data => ({
                        ...data,
                        [tabSelected]: {
                          ...data[tabSelected],
                          controllerManager: checked
                        }
                      }))
                      setMyClusterData(myClusterData => ({
                        ...myClusterData,
                        [tabSelected]: {
                          ...myClusterData[tabSelected],
                          controllerManager: checked
                        }
                      }));
                      setError({
                        status: false,
                        msg: ""
                      });
                    }} />
                </Tooltip>
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <Box
                  title={<p><IntlMessages id="cluster.scheduler.title" /></p>}
                  subtitle={<IntlMessages id="cluster.scheduler.subTitle" />}
                  titleStyle={innerTitleStyle}
                  subTitleStyle={innerSubtitleStyle}
                  style={{ display: "flex", justifyContent: "space-between", padding: "20px 0" }}
                />
                <Tooltip title={isUpdate ? "This field cannot be updated." : ""} placement='topLeft'>
                  <Switch style={myClusterData[tabSelected].scheduler ? { backgroundColor: "#132f44" } : {}}
                    disabled={isUpdate}
                    checked={myClusterData[tabSelected].scheduler}
                    onFocus={handleFocus}
                    onChange={(checked) => {
                      setData(data => ({
                        ...data,
                        [tabSelected]: {
                          ...data[tabSelected],
                          scheduler: checked
                        }
                      }))
                      setMyClusterData(myClusterData => ({
                        ...myClusterData,
                        [tabSelected]: {
                          ...myClusterData[tabSelected],
                          scheduler: checked
                        }
                      }));
                      setError({
                        status: false,
                        msg: ""
                      });
                    }} />
                </Tooltip>
              </div>
            </Box>
          </div>}
        </>}
      <div style={{ marginBottom: "80px" }}></div>
      <CreateTaskFooter buttonContent={"Cluster"} givenButtonFunction1={handleSubmit} openDrawer={openDrawer} />
    </ProdContentHold >
  );
};
