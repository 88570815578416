import styled from 'styled-components';
import Select from '@iso/components/uielements/select';
import WithDirection from '@iso/lib/helpers/rtl';
import InputBox from '../Ecommerce/Checkout/InputBox';
import { Switch } from 'antd';

const WDSelect = styled(Select)`
    &.ant-select {
        height: inherit;
        .ant-select-selection-wrap {
            align-self: center;
        }
        .ant-select-selector{
            width: 100%;
            height: 44px;
            // position: relative;
            // cursor: pointer;
            border: 1px solid #D0D5DD;
            border-radius: 8px;
            // &.ant-select-selection--single {
            //     width: 600px;
            //     height: 44px;
            //     // position: relative;
            //     // cursor: pointer;
            //     border: 1px solid #D0D5DD;
            //     border-radius: 8px;
            // }
        }

        .ant-select-single {
            height: inherit;
        }

        .ant-select-selection-placeholder, .ant-select-selection-item{
            display: flex;
            align-items: center;
        }

        .ant-select-selection-search .ant-select-selection-search-input{
            height: 42px;
        }
        .ant-select-dropdown{
            z-index: 1;
        }
        
        &.ant-select-multiple .ant-select-selection-overflow-item {
            top: auto !important; /* Adjust this value to your preference */
        }

        .ant-select-selection-item {
            height: auto;
        }
        
        &.customVersions .ant-select-selection-item{
            align-items: start;
            padding: 8px 0px;
        }
    }

    &.centricText{
        .ant-select-selector{
            .ant-select-selection-item > div{
                align-items: start !important;
                width: 100%;
                padding-top: 6px;
            }
        }
    }
`;


const isoSelect = WithDirection(WDSelect);

const SelectOption = Select.Option;


export default isoSelect;
export {
    SelectOption, //isoSwitch
};