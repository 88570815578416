import React from "react";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useState } from "react";

const { Dragger } = Upload;

const UploadFiles = ({ types, files, setFiles, handleReadFile, setFileChanged, editing, maxSize }) => {

    // const [fileList, setFileList] = useState([]);

    const props = {
        name: "file",
        multiple: false,
        fileList: files,  // Controlled fileList
        beforeUpload: (file) => {
            console.log("file :", file);
            const fileExtension = file.name.substring(file.name.lastIndexOf("."), file.name.length);
            // console.log("fileExtension :", fileExtension);
            console.log("fileExtension :", fileExtension);
            if (file.size > maxSize) {
                message.error(`File size should not exceed ${(maxSize / (1024 * 1024))}MB.`);
                return Upload.LIST_IGNORE;
            }
            else if (!types.includes(fileExtension)) {
                message.error(`${file.name} is not an allowed file type`);
                return Upload.LIST_IGNORE;
            }
            else {
                if(files.length > 0){
                    console.log("File changed: ", true);
                }
                if(editing){
                    setFileChanged(true);
                }
                handleReadFile(file);
            }

            return false;
        },
        onChange(info) {
            if (info.file.status === 'removed') {
                console.log("file removed");
                setFiles(); // Clear the list when file is removed
                if(editing){
                    setFileChanged(true);
                }
            }
        },
        onRemove: (file) => {
            setFiles(); // Reset the fileList when file is removed
            if(editing){
                setFileChanged(true);
            }
        },
    };

    return (
        <Dragger {...props}>
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">
                Browse or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">Only {types.includes('.env') ? ".env" : types} files are allowed</p>
        </Dragger>
    );
};

export default UploadFiles;