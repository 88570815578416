import { useContext } from "react";
import { AppContext } from "../../../context/ContextProvider";

export default function useEnvironmentVariables(currentProject) {
    console.log("My current project updated...", currentProject);
    const addEnvPayloadGen = (callSecretApi, foundEmpty, envData) => {
        let payload = [];

        console.log("Entering check...", payload, callSecretApi, currentProject);

        currentProject.tasks.every((temp, idx) => {
            if (temp.columnId === "app") {
                // myAppIdx.push(temp.id);
                console.log("temp in handleCreate :", temp);

                temp.envs.every((env) => {
                    let found = false;
                    let myObj = {};


                    if (env.value === "" || env.value === undefined) {
                        foundEmpty = true;
                        currentProject.tasks[idx].appError = true;
                        return false;   // helps in breaking every() loop
                    }
                    // check if already exist in envs list
                    payload.map((pay, i) => {
                        console.log("Payload check: ", pay, temp);
                        if (pay.id === temp.serviceId) {
                            found = true;
                            if (!env.isSecret && !callSecretApi) {
                                pay["env_var"][env.varName] = {
                                    "type": env.valueFrom,
                                    "value": env.value,//env.isSecret ? encryptSecrets(env.value) : env.value,
                                    "is_secret": env.isSecret,
                                    "depend_on": env.dependOn,
                                    "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                    "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                }
                            }
                            else if (env.isSecret) {
                                if (callSecretApi) {
                                    pay["env_var"][env.varName] = {
                                        "type": env.valueFrom,
                                        "value": env.value,//env.isSecret ? encryptSecrets(env.value) : env.value,
                                        "is_secret": env.isSecret,
                                        "depend_on": env.dependOn,
                                        "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                        "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                        "is_delete_from_atmosly": false,
                                        "is_delete_from_secret_manager": false
                                    }
                                }
                                else {
                                    pay["env_var"][env.varName] = {
                                        "type": env.valueFrom,
                                        "value": env.value,//env.isSecret ? encryptSecrets(env.value) : env.value,
                                        "is_secret": env.isSecret,
                                        "depend_on": env.dependOn,
                                        "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                        "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                    }
                                }
                            }
                        }
                    });

                    console.log("found :", found);

                    // if not
                    if (!found) {
                        console.log("temp: ", temp);
                        if (!env.isSecret && !callSecretApi) {
                            myObj = {
                                "id": temp.serviceId,
                                // "pass_at_build_time": currentProject.accountDetails.account_id && temp.isSecret ? undefined : temp.injectValue === "Build Time" ? true : false,
                                "is_secret_manager": callSecretApi ? currentProject.accountDetails?.account_id ? true : false : undefined,
                                "secret_details": callSecretApi ? currentProject.accountDetails?.account_id ? {
                                    "account_id": currentProject.accountDetails?.account_id,
                                    "region": currentProject.accountDetails?.region,
                                    "secret_name": (temp.secret_name && temp.secret_name !== "" ? temp.secret_name : currentProject.name + '_' + temp.name),

                                } : undefined : undefined,
                                "env_var": {
                                    [env.varName]: {
                                        "type": env.valueFrom,
                                        "value": env.value,//env.isSecret ? encryptSecrets(env.value) : env.value,
                                        "is_secret": env.isSecret,
                                        "depend_on": env.dependOn,
                                        "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                        "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                        "is_delete_from_atmosly": false,
                                        "is_delete_from_secret_manager": false
                                    }
                                }
                            };
                        }
                        else {
                            if (callSecretApi) {
                                myObj = {
                                    "id": temp.serviceId,
                                    // "pass_at_build_time": currentProject.accountDetails.account_id && temp.isSecret ? undefined : temp.injectValue === "Build Time" ? true : false,
                                    "is_secret_manager": callSecretApi ? currentProject.accountDetails?.account_id ? true : false : undefined,
                                    "secret_details": callSecretApi ? currentProject.accountDetails?.account_id ? {
                                        "account_id": currentProject.accountDetails?.account_id,
                                        "region": currentProject.accountDetails?.region,
                                        "secret_name": (temp.secret_name && temp.secret_name !== "" ? temp.secret_name : currentProject.name + '_' + temp.name),

                                    } : undefined : undefined,
                                    "env_var": {
                                        [env.varName]: {
                                            "type": env.valueFrom,
                                            "value": env.value,//env.isSecret ? encryptSecrets(env.value) : env.value,
                                            "is_secret": env.isSecret,
                                            "depend_on": env.dependOn,
                                            "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                            "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                            "is_delete_from_atmosly": false,
                                            "is_delete_from_secret_manager": false
                                        }
                                    }
                                };
                            }
                            else {
                                myObj = {
                                    "id": temp.serviceId,
                                    // "pass_at_build_time": currentProject.accountDetails.account_id && temp.isSecret ? undefined : temp.injectValue === "Build Time" ? true : false,
                                    "is_secret_manager": callSecretApi ? currentProject.accountDetails?.account_id ? true : false : undefined,
                                    "secret_details": callSecretApi ? currentProject.accountDetails?.account_id ? {
                                        "account_id": currentProject.accountDetails?.account_id,
                                        "region": currentProject.accountDetails?.region,
                                        "secret_name": (temp.secret_name && temp.secret_name !== "" ? temp.secret_name : currentProject.name + '_' + temp.name),

                                    } : undefined : undefined,
                                    "env_var": {
                                        [env.varName]: {
                                            "type": env.valueFrom,
                                            "value": env.value,//env.isSecret ? encryptSecrets(env.value) : env.value,
                                            "is_secret": env.isSecret,
                                            "depend_on": env.dependOn,
                                            "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                            "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                        }
                                    }
                                };
                            }
                        }
                        if (callSecretApi) {
                            if (env.isSecret) {
                                payload.push(myObj);
                            }
                        }
                        else {
                            // if (!env.isSecret) {
                            payload.push(myObj);
                            // }
                        }
                    }

                    return true;  // helps in continue loop
                });

                return true;  // helps in continue loop
            }
            else {
                return true;
            }
        });

        console.log("My payload: ", payload);
        return payload;
    }

    const updateEnvPayloadGen = (callSecretApi, foundEmpty) => {
        let payload = [];

        console.log("Entering check...", callSecretApi, currentProject);

        currentProject.tasks.every((temp, idx) => {
            if (temp.columnId === "app") {
                // myAppIdx.push(temp.id);
                console.log("temp in handleCreate :", temp);

                temp.envs.every((env) => {
                    let found = false;

                    if (env.value === "" || env.value === undefined) {
                        foundEmpty = true;
                        currentProject.tasks[idx].appError = true;
                        return false;   // helps in breaking every() loop
                    }
                    console.log("My payload start: ", payload);
                    // check if already exist in envs list
                    payload.map((pay, i) => {
                        if (pay.id === temp.serviceId) {
                            found = true;
                            if (!env.isSecret && !callSecretApi) {
                                pay["env_var"][env.varName] = {
                                    "type": env.valueFrom,
                                    "value": env.value,//env.isSecret ? encryptSecrets(env.value) : env.value,
                                    "is_secret": env.isSecret,
                                    "depend_on": env.dependOn,
                                    "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                    "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                }
                            }
                            else if (env.isSecret) {
                                if (callSecretApi && env.originalKey !== env.varName) {
                                    console.log("My env: ", env);
                                    //delete the original key
                                    pay["env_var"][env.originalKey] = {
                                        "type": env.valueFrom,
                                        "value": env.value,//env.isSecret ? encryptSecrets(env.value) : env.value,
                                        "is_secret": env.isSecret,
                                        "depend_on": env.dependOn,
                                        "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                        "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                        "is_delete_from_atmosly": true,
                                        "is_delete_from_secret_manager": true
                                    }
                                    // add the new key to the payload 
                                    pay["env_var"][env.varName] = {
                                        "type": env.valueFrom,
                                        "value": env.value,//env.isSecret ? encryptSecrets(env.value) : env.value,
                                        "is_secret": env.isSecret,
                                        "depend_on": env.dependOn,
                                        "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                        "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                        "is_delete_from_atmosly": false,
                                        "is_delete_from_secret_manager": false
                                    }
                                }
                                else {
                                    if (callSecretApi) {
                                        // myObj = {
                                        //     "id": temp.serviceId,
                                        //     // "pass_at_build_time": currentProject.accountDetails.account_id && temp.isSecret ? undefined : temp.injectValue === "Build Time" ? true : false,
                                        //     "is_secret_manager": callSecretApi ? currentProject.accountDetails?.account_id ? true : false : undefined,
                                        //     "secret_details": callSecretApi ? currentProject.accountDetails?.account_id ? {
                                        //         "account_id": currentProject.accountDetails?.account_id,
                                        //         "region": currentProject.accountDetails?.region,
                                        //         "secret_name": (temp.secret_name && temp.secret_name !== "" ? temp.secret_name : currentProject.name + '_' + temp.name),

                                        //     } : undefined : undefined,
                                        //     "env_var": {
                                        //         [env.varName]: {
                                        //             "type": env.valueFrom,
                                        //             "value": env.value,//env.isSecret ? encryptSecrets(env.value) : env.value,
                                        //             "is_secret": env.isSecret,
                                        //             "depend_on": env.dependOn,
                                        //             "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                        //             "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                        //             "is_delete_from_atmosly": false,
                                        //             "is_delete_from_secret_manager": false
                                        //         }
                                        //     }
                                        // };
                                        console.log("My env var in pay: ", pay);
                                        pay["env_var"][env.varName] = {
                                            "type": env.valueFrom,
                                            "value": env.value,//env.isSecret ? encryptSecrets(env.value) : env.value,
                                            "is_secret": env.isSecret,
                                            "depend_on": env.dependOn,
                                            "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                            "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                            "is_delete_from_atmosly": false,
                                            "is_delete_from_secret_manager": false
                                        }
                                    }
                                    else {
                                        pay["env_var"][env.varName] = {
                                            "type": env.valueFrom,
                                            "value": env.value,//env.isSecret ? encryptSecrets(env.value) : env.value,
                                            "is_secret": env.isSecret,
                                            "depend_on": env.dependOn,
                                            "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                            "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                        };
                                    }
                                }
                            }
                        }
                    });

                    console.log("found :", found);

                    // if not
                    if (!found) {
                        let myObj = {};
                        console.log("temp: ", temp);
                        if (!env.isSecret && !callSecretApi) {
                            myObj = {
                                "id": temp.serviceId,
                                // "pass_at_build_time": currentProject.accountDetails.account_id && temp.isSecret ? undefined : temp.injectValue === "Build Time" ? true : false,
                                "is_secret_manager": callSecretApi ? currentProject.accountDetails?.account_id ? true : false : undefined,
                                "secret_details": callSecretApi ? currentProject.accountDetails?.account_id ? {
                                    "account_id": currentProject.accountDetails?.account_id,
                                    "region": currentProject.accountDetails?.region,
                                    "secret_name": (temp.secret_name && temp.secret_name !== "" ? temp.secret_name : currentProject.name + '_' + temp.name),

                                } : undefined : undefined,
                                "env_var": {
                                    [env.varName]: {
                                        "type": env.valueFrom,
                                        "value": env.value,//env.isSecret ? encryptSecrets(env.value) : env.value,
                                        "is_secret": env.isSecret,
                                        "depend_on": env.dependOn,
                                        "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                        "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                    }
                                }
                            };
                        }
                        else {
                            if (callSecretApi && env.originalKey !== env.varName) {
                                console.log("My secret: ", env);
                                myObj = {
                                    "id": temp.serviceId,
                                    // "pass_at_build_time": currentProject.accountDetails.account_id && temp.isSecret ? undefined : temp.injectValue === "Build Time" ? true : false,
                                    "is_secret_manager": callSecretApi ? currentProject.accountDetails?.account_id ? true : false : undefined,
                                    "secret_details": callSecretApi ? currentProject.accountDetails?.account_id ? {
                                        "account_id": currentProject.accountDetails?.account_id,
                                        "region": currentProject.accountDetails?.region,
                                        "secret_name": (temp.secret_name && temp.secret_name !== "" ? temp.secret_name : currentProject.name + '_' + temp.name),

                                    } : undefined : undefined,
                                    "env_var": {
                                        [env.varName]: {
                                            "type": env.valueFrom,
                                            "value": env.value,//env.isSecret ? encryptSecrets(env.value) : env.value,
                                            "is_secret": env.isSecret,
                                            "depend_on": env.dependOn,
                                            "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                            "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                            "is_delete_from_atmosly": false,
                                            "is_delete_from_secret_manager": false
                                        },
                                        [env.originalKey]: {
                                            "type": env.valueFrom,
                                            "value": env.value,//env.isSecret ? encryptSecrets(env.value) : env.value,
                                            "is_secret": env.isSecret,
                                            "depend_on": env.dependOn,
                                            "is_for_build_time": true, //build/run time injection
                                            "is_for_run_time": false, //build/run time injection
                                            "is_delete_from_atmosly": true,
                                            "is_delete_from_secret_manager": true
                                        }
                                    }
                                };
                            }
                            else {
                                if (callSecretApi) {
                                    myObj = {
                                        "id": temp.serviceId,
                                        // "pass_at_build_time": currentProject.accountDetails.account_id && temp.isSecret ? undefined : temp.injectValue === "Build Time" ? true : false,
                                        "is_secret_manager": callSecretApi ? currentProject.accountDetails?.account_id ? true : false : undefined,
                                        "secret_details": callSecretApi ? currentProject.accountDetails?.account_id ? {
                                            "account_id": currentProject.accountDetails?.account_id,
                                            "region": currentProject.accountDetails?.region,
                                            "secret_name": (temp.secret_name && temp.secret_name !== "" ? temp.secret_name : currentProject.name + '_' + temp.name),

                                        } : undefined : undefined,
                                        "env_var": {
                                            [env.varName]: {
                                                "type": env.valueFrom,
                                                "value": env.value,//env.isSecret ? encryptSecrets(env.value) : env.value,
                                                "is_secret": env.isSecret,
                                                "depend_on": env.dependOn,
                                                "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                                "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                                "is_delete_from_atmosly": false,
                                                "is_delete_from_secret_manager": false
                                            }
                                        }
                                    };
                                }
                                else {
                                    myObj = {
                                        "id": temp.serviceId,
                                        // "pass_at_build_time": currentProject.accountDetails.account_id && temp.isSecret ? undefined : temp.injectValue === "Build Time" ? true : false,
                                        "is_secret_manager": callSecretApi ? currentProject.accountDetails?.account_id ? true : false : undefined,
                                        "secret_details": callSecretApi ? currentProject.accountDetails?.account_id ? {
                                            "account_id": currentProject.accountDetails?.account_id,
                                            "region": currentProject.accountDetails?.region,
                                            "secret_name": (temp.secret_name && temp.secret_name !== "" ? temp.secret_name : currentProject.name + '_' + temp.name),

                                        } : undefined : undefined,
                                        "env_var": {
                                            [env.varName]: {
                                                "type": env.valueFrom,
                                                "value": env.value,//env.isSecret ? encryptSecrets(env.value) : env.value,
                                                "is_secret": env.isSecret,
                                                "depend_on": env.dependOn,
                                                "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                                "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                            }
                                        }
                                    };
                                }
                            }
                        }
                        if (callSecretApi) {
                            if (env.isSecret) {
                                payload.push(myObj);
                            }
                        }
                        else {
                            // if (!env.isSecret) {
                            payload.push(myObj);
                            // }
                        }
                    }

                    return true;  // helps in continue loop
                });

                return true;  // helps in continue loop
            }
            else {
                return true;
            }
        });

        console.log("My payload: ", payload);
        return payload;
    }

    const importEnvPayloadGen = (callSecretApi, selectedAppId) => {
        let foundEmpty = false;
        let checkError = false;
        let payloadAtmosly = [], payloadSecretManager = [], selectedIdxs = [];
        currentProject.tasks.every((temp, idx) => {
            if (temp.columnId === "app") {
                console.log("temp in handleCreate :", temp);

                if (temp.envs.length === 0) {
                    if (!payloadAtmosly.some(val => val.id === temp.serviceId)) {
                        payloadAtmosly.push({
                            "id": temp.serviceId,
                            "is_secret_manager": callSecretApi ? currentProject.accountDetails?.account_id ? true : false : undefined,
                            "secret_details": callSecretApi ? currentProject.accountDetails?.account_id ? {
                                "account_id": currentProject.accountDetails?.account_id,
                                "region": currentProject.accountDetails?.region,
                                "secret_name": (temp.secret_name && temp.secret_name !== "" ? temp.secret_name : currentProject.name + '_' + temp.name),
                            } : undefined : undefined,
                            "env_var": {}
                        });
                    }
                    if (!payloadSecretManager.some(val => val.id === temp.serviceId)) {
                        payloadSecretManager.push({
                            "id": temp.serviceId,
                            "is_secret_manager": currentProject.accountDetails?.account_id ? true : false,
                            "secret_details": currentProject.accountDetails?.account_id ? {
                                "account_id": currentProject.accountDetails?.account_id,
                                "region": currentProject.accountDetails?.region,
                                "secret_name": (temp.secret_name && temp.secret_name !== "" ? temp.secret_name : currentProject.name + '_' + temp.name),
                            } : undefined,
                            "env_var": {}
                        });
                    }
                }

                temp.envs.every((env) => {
                    let found = false;

                    if (env.value === "" || env.value === undefined) {
                        foundEmpty = true;
                        currentProject.tasks[idx].appError = true;
                        selectedIdxs.push(idx); //checks in what service the error exists
                        return false;   // helps in breaking every() loop
                    }
                    else if (env.error && env.lastAction !== "Delete") {
                        checkError = true;
                        selectedIdxs.push(idx); //checks in what service the error exists
                        return false;
                    }
                    // check if already exist in envs list
                    payloadAtmosly.map((pay, i) => {
                        console.log("Pay1: ", pay);
                        if (pay.id === temp.serviceId && (!env.isSecret || (env.isSecret && !currentProject.accountDetails.account_id))) {
                            found = true;
                            pay["env_var"][env.varName] = {
                                "type": env.valueFrom,
                                "value": env.value,
                                "is_secret": env.isSecret,
                                "depend_on": env.dependOn,
                                "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                            }
                        }
                    });

                    payloadSecretManager.map((pay, i) => {
                        console.log("Pay2: ", pay, env, callSecretApi);
                        if (pay.id === temp.serviceId && env.isSecret && currentProject.accountDetails.account_id) {
                            found = true;
                            if (env.lastAction === "Update") {
                                console.log("My var: ", env);
                                pay["env_var"][env.originalKey] = {
                                    "type": env.valueFrom,
                                    "value": env.value,
                                    "is_secret": env.isSecret,
                                    "depend_on": env.dependOn,
                                    "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                    "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                    "is_delete_from_atmosly": true,
                                    "is_delete_from_secret_manager": true
                                };
                                pay["env_var"][env.varName] = {
                                    "type": env.valueFrom,
                                    "value": env.value,
                                    "is_secret": env.isSecret,
                                    "depend_on": env.dependOn,
                                    "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                    "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                    "is_delete_from_atmosly": false,
                                    "is_delete_from_secret_manager": false
                                };
                            }
                            else if (env.lastAction === "Delete") {
                                pay["env_var"][env.varName] = {
                                    "type": env.valueFrom,
                                    "value": env.value,
                                    "is_secret": env.isSecret,
                                    "depend_on": env.dependOn,
                                    "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                    "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                    "is_delete_from_atmosly": true,
                                    "is_delete_from_secret_manager": env.deleteFromManager
                                };
                            }
                            else {
                                pay["env_var"][env.varName] = {
                                    "type": env.valueFrom,
                                    "value": env.value,
                                    "is_secret": env.isSecret,
                                    "depend_on": env.dependOn,
                                    "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                    "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                    "is_delete_from_atmosly": false,
                                    "is_delete_from_secret_manager": false
                                };
                            }
                        }
                    });

                    console.log("found :", found);
                    // if not
                    if (!found) {
                        console.log("temp: ", temp);
                        let myObjAtmosly = {
                            "id": temp.serviceId,
                            // "pass_at_build_time": currentProject.accountDetails.account_id && temp.isSecret ? undefined : temp.injectValue === "Build Time" ? true : false,
                            "is_secret_manager": callSecretApi ? currentProject.accountDetails?.account_id ? true : false : undefined,
                            "secret_details": callSecretApi ? currentProject.accountDetails?.account_id ? {
                                "account_id": currentProject.accountDetails?.account_id,
                                "region": currentProject.accountDetails?.region,
                                "secret_name": (temp.secret_name && temp.secret_name !== "" ? temp.secret_name : currentProject.name + '_' + temp.name),
                            } : undefined : undefined,
                            "env_var": {}
                        };
                        let myObjSecretManager = {
                            "id": temp.serviceId,
                            // "pass_at_build_time": currentProject.accountDetails.account_id && temp.isSecret ? undefined : temp.injectValue === "Build Time" ? true : false,
                            "is_secret_manager": currentProject.accountDetails?.account_id ? true : false,
                            "secret_details": currentProject.accountDetails?.account_id ? {
                                "account_id": currentProject.accountDetails?.account_id,
                                "region": currentProject.accountDetails?.region,
                                "secret_name": (temp.secret_name && temp.secret_name !== "" ? temp.secret_name : currentProject.name + '_' + temp.name),

                            } : undefined,
                            "env_var": {}
                        };
                        if (currentProject.accountDetails.account_id) {
                            if (env.isSecret) {
                                if (env.lastAction === "Update") {
                                    myObjSecretManager = {
                                        ...myObjSecretManager,
                                        "env_var": {
                                            ...myObjSecretManager.env_var,
                                            [env.originalKey]: {
                                                "type": env.valueFrom,
                                                "value": env.value,
                                                "is_secret": env.isSecret,
                                                "depend_on": env.dependOn,
                                                "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                                "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                                "is_delete_from_atmosly": true,
                                                "is_delete_from_secret_manager": true
                                            },
                                            [env.varName]: {
                                                "type": env.valueFrom,
                                                "value": env.value,
                                                "is_secret": env.isSecret,
                                                "depend_on": env.dependOn,
                                                "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                                "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                                "is_delete_from_atmosly": false,
                                                "is_delete_from_secret_manager": false
                                            }
                                        }
                                    };
                                    myObjSecretManager = {
                                        ...myObjSecretManager,
                                        "env_var": {
                                            ...myObjSecretManager.env_var,
                                            [env.varName]: {
                                                "type": env.valueFrom,
                                                "value": env.value,
                                                "is_secret": env.isSecret,
                                                "depend_on": env.dependOn,
                                                "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                                "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                                "is_delete_from_atmosly": false,
                                                "is_delete_from_secret_manager": false
                                            }
                                        }
                                    }
                                }
                                else if (env.lastAction === "Delete") {
                                    myObjSecretManager = {
                                        ...myObjSecretManager,
                                        "env_var": {
                                            ...myObjSecretManager.env_var,
                                            [env.originalKey]: {
                                                "type": env.valueFrom,
                                                "value": env.value,
                                                "is_secret": env.isSecret,
                                                "depend_on": env.dependOn,
                                                "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                                "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                                "is_delete_from_atmosly": true,
                                                "is_delete_from_secret_manager": env.deleteFromManager
                                            }
                                        }
                                    }
                                }
                                else {
                                    myObjSecretManager = {
                                        ...myObjSecretManager,
                                        "env_var": {
                                            ...myObjSecretManager.env_var,
                                            [env.varName]: {
                                                "type": env.valueFrom,
                                                "value": env.value,
                                                "is_secret": env.isSecret,
                                                "depend_on": env.dependOn,
                                                "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                                "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                                "is_delete_from_atmosly": false,
                                                "is_delete_from_secret_manager": false
                                            }
                                        }
                                    }
                                }
                            }
                            else {
                                myObjAtmosly = {
                                    ...myObjAtmosly,
                                    "env_var": {
                                        ...myObjAtmosly.env_var,
                                        [env.varName]: {
                                            "type": env.valueFrom,
                                            "value": env.value,
                                            "is_secret": env.isSecret,
                                            "depend_on": env.dependOn,
                                            "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                            "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                        }
                                    }
                                }
                            }
                        }
                        else {
                            myObjAtmosly = {
                                ...myObjAtmosly,
                                "env_var": {
                                    ...myObjAtmosly.env_var,
                                    [env.varName]: {
                                        "type": env.valueFrom,
                                        "value": env.value,
                                        "is_secret": env.isSecret,
                                        "depend_on": env.dependOn,
                                        "is_for_build_time": env.injectAt.includes("Build Time"), //build/run time injection
                                        "is_for_run_time": env.injectAt.includes("Run Time"), //build/run time injection
                                    }
                                }
                            }
                        }
                        console.log('callSecrets: ', callSecretApi, myObjAtmosly, myObjSecretManager);
                        payloadAtmosly.push(myObjAtmosly);
                        payloadSecretManager.push(myObjSecretManager);
                    }

                    return true;  // helps in continue loop
                });

                return true;  // helps in continue loop
            }
            else {
                return true;
            }
        });
        console.log("CheckError: ", checkError, foundEmpty);
        return { payloadAtmosly, payloadSecretManager, selectedIdxs, foundEmpty, checkError };
    }

    return { addEnvPayloadGen, updateEnvPayloadGen, importEnvPayloadGen };
}