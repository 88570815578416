import React, { useEffect, useState } from 'react'
import dashboardIcon from '../assets/images/breadDashboard.svg';
import usersIcon from '../assets/images/breadUser.svg';
import integrationsIcon from '../assets/images/breadIntegration.svg';
import clustersIcon from '../assets/images/breadCluster.svg';
import projectsIcon from '../assets/images/breadProject.svg';
import deploymentsIcon from '../assets/images/breadEnvironment.svg';
import pipelineIcon from '../assets/images/breadPipeline.svg';
import rolesAndPermissionsIcon from '../assets/images/breadRole.svg';
import securityIcon from '../assets/images/breadSecurity.svg';
import marketplaceIcon from '../assets/images/breadMarketplace.svg';
import notificationIcon from '../assets/images/notificationBreadcrumb.svg';
import guardrailIcon from '../assets/images/guardrailBread.svg';
import { useLocation } from 'react-router-dom';
import api from '../api';
import analyzingIcon from '../assets/images/analyzingIcon.svg'

const BreadcrumbContext = React.createContext();

const BreadcrumbProvider = ({ children }) => {

    // let location = useLocation();
    // let clusterId = location.pathname.split('/').slice(-1)[0];

    //let clusterName = 

    let initialHeadings = {

        dashboard: {
            dashboard: {
                title: "Dashboard",
                subTitle: "Detailed information of Dashboard"
            },
            notifications: {
                title: "Notifications",
                subTitle: "Detailed information of Notifications"
            }
        },
        costAnalysis: {
            costAnalysis: {
                title: "Cost Analysis",
                subTitle: "Account Costs"
            },
        },
        superDashboard: {
            superDashboard: {
                title: "Dashboard",
                subTitle: "Detailed information of super admin dashboard"
            },
        },
        securityVulnerabilities: {
            securityVulnerabilities: {
                title: "Scan Report",
                subTitle: "Scan Details"
            },
            vulnerabilityList: {
                title: "Security Scans",
                subTitle: "List of all the scans",
                buttonContent: "Scan"
            }
        },
        users: {
            users: {
                title: "Users",
                subTitle: "Detailed list of Users",
                buttonContent: "Invite User"
            },
            inviteUser: {
                title: "Invite User",
                subTitle: "Invite User details"
            },
            updateUser: {
                title: "Update User",
                subTitle: "Detail of all Update User"
            },
            profile: {
                title: "My Profile",
                subTitle: "Profile details"
            }
        },
        integrations: {
            integrations: {
                title: "Integrations",
                subTitle: "Detail regarding Integration"
            }
        },
        clusters: {
            clusters: {
                title: "Clusters",
                subTitle: "Detailed list of all clusters",
                buttonContent: "Add Cluster",
                path: "/clusters/create"
            },
            createCluster: {
                createCluster: {
                    title: "Add Cluster",
                    subTitle: "Detail regarding adding clusters"
                },
                clusterAddons: {
                    title: "Add Addons",
                    subTitle: "Detail regarding EKS addons"
                },
                clusterPreview: {
                    title: "Cluster Preview",
                    subTitle: "Details of your Cluster",
                    buttonContent: "Total Estimated Cost - ",
                    dataTransferCost: "0",
                    eksClusterCost: "0",
                    instanceTypeCost: "0",
                    loadBalanceCost: "0",
                    natGatewayCost: "0",
                    storageCost: "0",
                    totalCost: "0",
                    fetching: false,
                },
            },
            editCluster: {
                editCluster: {
                    title: "Edit Cluster",
                    subTitle: "Detail regarding edit cluster"
                },
                clusterAddons: {
                    title: "Edit Addons",
                    subTitle: "Detail regarding EKS addons"
                },
                clusterPreview: {
                    title: "Cluster Preview",
                    subTitle: "Details of your Cluster",
                    buttonContent: "Total Estimated Cost - ",
                    dataTransferCost: "0",
                    eksClusterCost: "0",
                    instanceTypeCost: "0",
                    loadBalanceCost: "0",
                    natGatewayCost: "0",
                    storageCost: "0",
                    totalCost: "0",
                    fetching: false,
                },
            },
            clusterOverview: {
                clusterOverview: {
                    title: "Cluster Details",
                    subTitle: "Details of Cluster Details",
                },
                clusterBasicDetails: {
                    title: "Basic Details",
                    subTitle: "Detailed information of Cluster",
                },
                clusterLogs: {
                    title: "Cluster Logs",
                    subTitle: "Detailed Cluster logs"
                },
                clusterBootstrapLogs: {
                    title: "Cluster Addon Logs",
                    subTitle: "Detailed Addons logs"
                },
                clusterNodgroups: {
                    title: "Nodegroups Details",
                    subTitle: "Detailed information of Nodegroups",
                    buttonContent: "Add Nodegroups",
                    path: "/all-projects/create"
                },
                clusterAddNodgroups: {
                    title: "Add Nodegroups",
                    subTitle: "Detail regarding adding Nodegroups",
                    title2: "Update Nodegroups",
                    subTitle2: "Detail regarding updating Nodegroups",
                    buttonContent: "Nodegroups Cost - ",
                    totalCost: "",
                    hide: false,
                    minCost: "",
                    maxCost: "",
                },
                // clusterUpdateNodgroups: {
                //     title: "Update Nodegroups",
                //     subTitle: "Detail regarding updating Nodegroups",
                //     buttonContent: "Nodegroups Cost - ",
                //     totalCost: "",
                //     hide: false,
                //     minCost: "",
                //     maxCost: "",
                // },
                marketplaceDetails: {
                    title: "Marketplace Details",
                    subTitle: "Detailed information of deployed Marketplaces",
                    buttonContent: "Add Marketplace",
                },
                rbacDetails: {
                    title: "RBAC",
                    subTitle: "Detailed information of RBAC",
                    buttonContent: "Create Roles"
                },
                createRBACDetails: {
                    title: "Create Roles",
                    subTitle: "Details regarding to adding roles",
                    title2: "Update Role",
                    subTitle2: "Update role details",
                }
            },
        },
        projects: {
            projects: {
                title: "Projects",
                subTitle: "Detailed list of projects",
                buttonContent: "Add Project",
                path: "/all-projects/create"
            },
            createProject: {
                createProject: {
                    title: "Create Project",
                    subTitle: "Detailed information of project"
                },
                projectServices: {
                    title: "Project Services",
                    subTitle: "Detailed information of project",
                    buttonContent1: "Create Project",
                    buttonContent2: "Edit Environment Variables"
                },
                projectEnvVariables: {
                    title: "Environment Variables",
                    subTitle: "Detailed information of Environment Variables",
                    //buttonContent: "Go to Projects List",
                },
                addWorkflow: {
                    title: "Add Workflow",
                    subTitle: "Details regarding add workflow."
                },
                updateWorkflow: {
                    title: "Update Workflow",
                    subTitle: "Details regarding update workflow."
                },
                workflowDetails: {
                    title: "Workflow Details",
                    subTitle: "Details regarding workflows"
                },
                updateWorkflowDetails: {
                    title: "Workflow Details",
                    subTitle: "Details regarding workflows"
                },
                executionHistory: {
                    title: "Execution History",
                    subTitle: "Details regarding Execution History"
                },
                workflowReports: {
                    title: "Report List",
                    subTitle: "Details regarding Reports"
                },
                workflowPipelines: {
                    title: "Workflow Release Details",
                    subTitle: "Detail regarding workflow release"
                },
                workflowReport: {
                    title: "Reports",
                    subTitle: "Detail regarding release report"
                }
            },
        },
        deployments: {
            deployments: {
                title: "Environments",
                subTitle: "Detailed list of all Environments",
                buttonContent: "Add Environment",
                path: "/environments/create"
            },
            createDeployment: {
                createDeployment: {
                    title: "Add Environment",
                    subTitle: "Details regarding adding environment"
                },
                deploymentServices: {
                    title: "Add Services",
                    subTitle: "Details about Add Services",
                    buttonContent: "Create Environment"
                },
                deploymentPreview: {
                    title: "Environment Preview",
                    subTitle: "Detailed information of Environment"
                },
            },
            deploymentOverview: {
                deploymentOverview: {
                    title: "Environment Overview",
                    subTitle: "Details regarding environment overview"
                },
                servicesOverview: {
                    title: "Services Overview",
                    subTitle: "Details about services Overview"
                }
                // },
                // logs: {
                //     title: "Pipeline Logs",
                //     subTitle: "Details about pipeline logs"
                // }
            },
            cloneDeployment: {
                cloneDeployment: {
                    title: "Clone Environment",
                    subTitle: "Detailed information of Environment"
                },
                cloneDeploymentServices: {
                    title: "Clone Environment Services",
                    subTitle: "Detailed information of Environment Services",
                    buttonContent: "Take me to important fields"
                }
            },
            editServices: {
                title: "Edit Services",
                subTitle: "Detailed information of Environment Services"
            },
            addServices: {
                title: "Add New Services",
                subTitle: "Detailed information of Environment Services",
                buttonContent: "Deploy"
            }
        },
        pipelines: {
            pipelines: {
                title: "Pipelines",
                subTitle: "Detailed information about Pipeline list"
            },
            buildLogs: {
                title: "Workflow Logs",
                subTitle: "Detailed information regarding logs"
            },
        },
        rolesAndPermissions: {
            rolesAndPermissions: {
                title: "Roles",
                subTitle: "Detailed information of Roles",
                buttonContent: "Add Role",
                path: "/roles-permissions/create"
            },
            createRolesAndPermissions: {
                createRolesAndPermissions: {
                    title: "Add Roles",
                    subTitle: "Detailed information of Roles"
                },
                // rolesAndPermissionsServices: "Service List",
                // rolesAndPermissionsPreview: "Deployment Preview",
            },
        },
        marketplace: {
            marketplace: {
                title: "Marketplace",
                subTitle: "Detail list of all MarketPlace",
                buttonContent: "Add repositories",
                // path: "/marketplace/create"
            },
            createMarketplace: {
                createMarketplace: {
                    title: "Add Marketplace",
                    subTitle: "Details of Add Marketplace"
                }
            },
            deployMarketplace: {
                deployMarketplace: {
                    title: "Deploy Marketplace",
                    subTitle: "Details of Deploy Marketplace"
                }
            },
            addRepository: {
                addRepository: {
                    title: "Add Repositories",
                    subTitle: "Details regarding adding repositories"
                }
            },
        },
        guardrails: {
            guardrails: {
                title: "Guardrail List",
                subTitle: "Guardrail list",
                buttonContent: "Add Guardrail",
            },
            policyList: {
                title: "Guardrail Templates",
                subTitle: "Guardrail Templates",
                buttonContent: "",
            },
            editGuardrail: {
                title: "Edit Guardrail",
                subTitle: "Edit details of the Guardrail Template",
                buttonContent: "Update",
            },
            createGuardrail: {
                title: "Create Guardrail",
                subTitle: "Edit details of the Guardrail Template",
                buttonContent: "Create Guardrail",
            },
            guardrailDetails: {
                title: "Guardrail Details",
                subTitle: "Details about Guardrails"
            }
        }

    };

    let initialBreadcrumbs = {
        dashboard: {
            dashboard: {
                path: "/dashboard",
                name: "Dashboard",
                image: dashboardIcon
            },
            notifications: {
                path: "/notifications",
                name: "Notifications",
                image: notificationIcon
            }
        },
        costAnalysis: {
            costAnalysis: {
                path: "/cost-analyser",
                name: "Cost Analysis",
                image: analyzingIcon
            }
        },
        superDashboard: {
            superDashboard: {
                path: "/super-dashboard",
                name: "Dashboard",
                image: dashboardIcon
            }
        },
        securityVulnerabilities: {
            securityVulnerabilities: {
                name: "Security Scans",
                path: "/vulnerability-list",
                image: securityIcon,
            },
            filters: {
                path: "/security-vulnerability-list",
                name: "Scan Report"
            },
            vulnerabilityList: {
                path: "/vulnerability-list",
                name: "Security Scans",
                image: securityIcon,
            }
        },
        users: {
            users: {
                path: "/users",
                name: "Users",
                image: usersIcon
                //usersList: []
            },
            inviteUser: {
                // path: "/users/invite",
                name: "Invite User"
            },
            updateUser: {
                path: "/users/update",
                name: "Update User"
            },
            profile: {
                path: "/my-profile",
                name: "My Profile"
            }
        },
        integrations: {
            integrations: {
                path: "/integrations",
                name: "Integrations",
                image: integrationsIcon
            }
        },
        clusters: {
            clusters: {
                path: "/clusters",
                name: "Clusters",
                clustersList: [],
                redirectUrl: '',
                // apiEndPoint: '/api/cluster/',
                image: clustersIcon
                //isDropdown: false
            },
            createCluster: {
                path: "/clusters/create",
                name: "Add Cluster",
                //isDropdown: false
            },
            clusterAddons: {
                path: "/clusters/addons",
                name: "Add Addons"
            },
            editCluster: {
                path: "",
                name: "Edit Cluster"
            },
            editAddons: {
                path: "/clusters/addons",
                name: "Edit Addons"
            },
            clusterPreview: {
                path: "",
                name: "Cluster Preview"
            },
            clusterOverview: {
                //path: `/clusters/cluster/${clusterId}`,
                name: "Cluster Details"
            },
            nodegroups: {
                //path: `/clusters/cluster/${clusterId}`,
                name: "Nodegroups"
            },
            addNodegroups: {
                name: "Add Nodegroup"
            },
            updateNodegroups: {
                name: "Update Nodegroup"
            },
            // nodegroups: {
            //     //path: `/clusters/cluster/${clusterId}`,
            //     name: "Nodegroups"
            // },
            marketplace: {
                //path: `/clusters/cluster/${clusterId}`,
                name: "Marketplace Details"
            },
            marketplaceValue: {
                //path: `/clusters/cluster/${clusterId}`,
                name: "Marketplace Details"
            },
            clusterLogs: {
                //path: `/clusters/cluster/${clusterId}`,
                name: "Cluster Logs"
            },
            bootstrapLogs: {
                //path: `/clusters/cluster/${clusterId}`,
                name: "Addons Logs"
            },
            rbac: {
                name: "RBAC",
            },
        },
        projects: {
            projects: {
                path: "/all-projects",
                name: "Projects",
                projectsList: [],
                // apiEndPoint: '/api/project_env/projects/',
                redirectUrl: '',
                image: projectsIcon
                //isDropdown: false
            },
            createProject: {
                path: "/all-projects/create",
                name: "Step-1: Create Project",
                //isDropdown: false
            },
            projectServices: {
                path: "/all-projects/create/services",
                name: "Step-2: Add Services"
            },
            editServices: {
                //path: "/all-projects/create/services",
                name: "Edit Services"
            },
            projectEnvVariables: {
                path: "/all-projects/",
                name: "Environment Variables",
                //isDropdown: false
            },
            addWorkflow: {
                path: "/all-projects/",
                name: "add workflow",
                //isDropdown: false
            },
            updateWorkflow: {
                path: "/all-projects/",
                name: "update workflow",
                //isDropdown: false
            },
            workflowDetails: {
                // path: "/all-projects/",
                name: "Workflow details",
                step1: "Step 1: Add Details",
                step2: "Step 2: Add Workflow Details",
                //isDropdown: false
            },
            updateWorkflowDetails: {
                // path: "/all-projects/",
                name: "Workflow Details",
                step1: "Step 1: Update Details",
                step2: "Step 2: Update Workflow Details",
                //isDropdown: false
            },
            executionHistory: {
                // path: "/all-projects/",
                name: "Execution History",
                //isDropdown: false
            },
            workflowReports: {
                // path: "/all-projects/",
                name: "Reports",
                //isDropdown: false
            },
            workflowPipelines: {
                name: "Workflow Details"
            }
        },
        deployments: {
            deployments: {
                path: "/environments",
                name: "Environments",
                // apiEndPoint: '/api/project_env/env/create/',
                redirectUrl: "",
                deploymentsList: [],
                image: deploymentsIcon
                //isDropdown: false
            },
            deploymentOverview: {
                name: "Environment Overview"
            },
            servicesOverview: {
                name: "Services Overview"
            },
            deploymentLogs: {
                path: "",
                name: "Pipeline Logs"
            },
            createDeployment: {
                path: "/environments/create",
                name: "Add Environment",
                //isDropdown: false
            },
            cloneDeployment: {
                path: "/environments/create",
                name: "Clone Environment"
            },
            editServices: {
                path: "/environments/environment/edit/services",
                name: "Edit Service"
            },
            addServices: {
                path: "/environments/environment/edit/services",
                name: "Add New Services"
            }
        },
        pipelines: {
            pipelines: {
                path: "/pipelines",
                name: "Pipelines",
                //apiEndPoint: '/api/project_env/env/create/',
                //redirectUrl: "",
                pipelinesList: [],
                image: pipelineIcon
                //isDropdown: false
            },
        },
        rolesAndPermissions: {
            rolesAndPermissions: {
                path: "/roles-permissions",
                name: "Roles",
                deploymentsList: [],
                image: rolesAndPermissionsIcon
                //isDropdown: false
            },
            createRolesAndPermissions: {
                path: "/roles-permissions/create",
                name: "Add Roles",
                //isDropdown: false
            }
        },
        marketplace: {
            marketplace: {
                path: "/marketplace",
                name: "Marketplace",
                marketplaceList: [],
                image: marketplaceIcon,
                isDropdown: false
            },
            createMarketplace: {
                path: "/marketplace/create",
                name: "Marketplace",
                isDropdown: false
            },
            addRepository: {
                path: "/marketplace/addrepo",
                name: "Add Repository",
                isDropdown: false
            },
        },
        guardrails: {
            guardrails: {
                path: "/guardrail",
                name: "Guardrails",
                guardrailList: [],
                image: guardrailIcon,
                isDropdown: false
            },
            policyList: {
                path: "/guardrail/add",
                name: "Select Template",
                isDropdown: false
            },
            guardrailDetails: {
                path: "",
                name: "Guardrail Details",
                isDropdown: false
            },
            editGuardrail: {
                path: "",
                name: "Edit Guardrail",
                isDropdown: false
            },
            createGuardrail: {
                path: "",
                name: "Create",
                isDropdown: false
            },
        }
    }

    const [pageHeadingsObj, setPageHeadingsObj] = useState(initialHeadings);
    const [breadcrumbObj, setBreadcrumbObj] = useState(initialBreadcrumbs);
    const [pageHeading, setPageHeading] = useState({
        title: "",
        subTitle: ""
    });
    const [breadData, setBreadData] = useState([]);
    //const [isbreadcrumbWithList, setIsBreadcrumbWithList] = useState(false);

    /*useEffect(() => {
        if(true){
            if(pageHeading.includes("Cluster")){
                setBreadcrumbObj(breadcrumbObj => ({
                    ...breadcrumbObj,
                    clusters: {
                        ...breadcrumbObj.clusters,
                        clusters: {
                            ...breadcrumbObj.clusters.clusters,
                            clustersList: ["Sample1", "Sample2", "Sample3"]
                        }
                    }
                }));
            }
            else if(pageHeading.includes("Project")){
                setBreadcrumbObj(breadcrumbObj => ({
                    ...breadcrumbObj,
                    projects: {
                        ...breadcrumbObj.projects,
                        projects: {
                            ...breadcrumbObj.projects.projects,
                            projectsList: ["Sample1", "Sample2", "Sample3"]
                        }
                    }
                }));
            }
            else{
                setBreadcrumbObj(breadcrumbObj => ({
                    ...breadcrumbObj,
                    deployments: {
                        ...breadcrumbObj.deployments,
                        deployments: {
                            ...breadcrumbObj.deployments.deployments,
                            deploymentsList: ["Sample1", "Sample2", "Sample3"]
                        }
                    }
                }));
            }
        }
    }, []);*/


    return (
        <BreadcrumbContext.Provider value={{ pageHeadingsObj, setPageHeadingsObj, breadcrumbObj, setBreadcrumbObj, pageHeading, setPageHeading, breadData, setBreadData }}>
            {children}
        </BreadcrumbContext.Provider>
    );
}

export { BreadcrumbContext, BreadcrumbProvider };