// import React, { useReducer } from 'react';
// import { Button, Tooltip } from 'antd';
// import { Wrapper, Title, Text, Icon } from './NoBoardFounds.style';
// import emptyProjectPlaceHolder from '@iso/assets/images/icon/12.svg';
// import { useEffect } from 'react';
// import { useContext } from 'react';
// import { SideBarContext } from '../../../../context/sidebarContext';
// import { hasPermission, permissionReducer } from '../../../../context/helper';
// import { BreadcrumbContext } from '../../../../context/breadcrumbContext';

// export default function NoBoardFounds({ location, navigate, section, action }) {

//   const { current, setCurrent } = useContext(SideBarContext);
//   const { pageHeading } = useContext(BreadcrumbContext);
//   let initialPermission = "";

//   const [permission, dispatch] = useReducer(permissionReducer, initialPermission);
//   console.log("Location: ", location);

//   useEffect(() => {
//     if (section === "Page not found") {
//       setCurrent([""]);
//     }
//   }, [section]);

//   useEffect(() => {
//     if (pageHeading.title !== "") {
//       console.log("UseEffect enters", pageHeading);
//       dispatch({ type: pageHeading.title });
//     }
//   }, [pageHeading.title]);

//   return (
//     <Wrapper className='not-found'>
//       <Icon src={emptyProjectPlaceHolder} />
//       {["Page not found"].includes(section) && <Title>Page Not Found</Title>}
//       {section != "Page not found" && (!["No Board"].includes(section) ? <Title>You currently have no {section}</Title> : <Title>No Data Found</Title>)}
//       {!["Pipelines", "No Board", "Page not found"].includes(section) && <Text>Let's create your first {section} in ATMOSLY</Text>}
//       {!["Pipelines", "No Board", "Page not found"].includes(section) && <>
//         {hasPermission(permission) ?
//           <Button style={{ opacity: hasPermission(permission) ? "1" : "0.5" }} disabled={!hasPermission(permission)} type="primary" onClick={() => {
//             if (["Security Scan"].includes(section)) {
//               action();
//             }
//             else {
//               (location.pathname === "/cost-analyser" || location.pathname === "/security-vulnerability-list") ? navigate("/integrations") : navigate(`${location.pathname}/create`)
//             }
//           }}>
//             {["Security Scan"].includes(section) ? `Run Scan` : `Create ${section}`}
//           </Button> : <Tooltip position="bottom" title="You don't have permission to perform this action."> <Button style={{ opacity: hasPermission(permission) ? "1" : "0.5", cursor: "not-allowed" }} disabled={!hasPermission(permission)} type="primary">
//             {["Security Scan"].includes(section) ? `Run Scan` : `Create ${section}`}
//           </Button>
//           </Tooltip>}
//       </>}
//     </Wrapper>
//   );
// }

import React from 'react';
import { Button } from 'antd';
import { Wrapper, Title, Text, Icon } from './NoBoardFounds.style';
import emptyProjectPlaceHolder from '@iso/assets/images/icon/12.svg';
import { useEffect } from 'react';
import { useContext } from 'react';
import { SideBarContext } from '../../../../context/sidebarContext';
import noData from '@iso/assets/images/noData.svg';
import { DeploymentContext } from '../../../../context/deploymentContext';
import Project from '../../../Projects/Project';
import { AppContext } from '../../../../context/ContextProvider';

export default function NoBoardFounds({ location, navigate, section, action ,...otherProps }) {

  const { current, setCurrent } = useContext(SideBarContext);
  const { setOpenModel } = useContext(AppContext);
  const { setCloneDep, setAddDep, setUpdateDep, setImportantEnabled } = useContext(DeploymentContext);
  console.log("Location: ", location);

  useEffect(() => {
    if (section === "Page not found") {
      setCurrent([""]);
    }
  }, [section]);

  return (
    <Wrapper className='not-found' {...otherProps}>
      {/* <Icon src={emptyProjectPlaceHolder} />
      {["Page not found"].includes(section) && <Title>Page Not Found</Title>}
      {section != "Page not found" && (!["No Board"].includes(section) ? <Title>You currently have no {section}</Title> : <Title>No Data Found</Title>)}
      {!["Pipelines", "No Board", "Page not found"].includes(section) && <Text>Let's create your first {section} in ATMOSLY</Text>}
      {!["Pipelines", "No Board", "Page not found"].includes(section) && <Button type="primary" onClick={() => {
        if(["Security Scan"].includes(section)){
          action();
        }
        else{
          (location.pathname === "/cost-analyser" || location.pathname === "/security-vulnerability-list") ? navigate("/integrations") : navigate(`${location.pathname}/create`)
        }
        }}>
        {["Security Scan"].includes(section) ? `Run Scan` : `Create ${section}`}
      </Button>} */}
      {console.log("Page not found entered...")}
      <Icon src={noData} />
      {["Page not found"].includes(section) && <Title>Page Not Found</Title>}
      {section != "Page not found" && (!["No Board"].includes(section) && !["Security Scan"].includes(section) ? <Title>You currently have no {section}</Title> : !["Security Scan"].includes(section) ? <Title>No Data Found</Title> : <></>)}
      {!["Pipelines", "No Board", "Page not found"].includes(section) && !["Security Scan"].includes(section) && <Text>Let's create your first {section} in ATMOSLY</Text>}
      {section != "Page not found" && (!["No Board"].includes(section) && ["Security Scan"].includes(section) && <Title>Your scan list is empty</Title>)}
      {!["Pipelines", "No Board", "Page not found"].includes(section) && ["Security Scan"].includes(section) && <Text>Click here to begin your first security and compliance check!</Text>}
      {!["Pipelines", "No Board", "Page not found"].includes(section) && <Button style={{
        display: "inline-flex",
        padding: "10px 57px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        background: "#132f44",
        color: "var(--color-white, #FFF)",
        fontFamily: "Nunito Sans",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "24px",
        marginTop: "20px"
      }} type="primary" onClick={() => {
        if (["Security Scan"].includes(section)) {
          action();
        }
        else {
          if (location.pathname === "Environments") {
            setCloneDep(false);
            setAddDep(false);
            setUpdateDep(false);
            localStorage.setItem('isClone', JSON.stringify(false));
            localStorage.setItem('updateDep', JSON.stringify(false));
            localStorage.setItem('addDep', JSON.stringify(false));
            localStorage.setItem('stopFetch', JSON.stringify(false));
            setImportantEnabled(false);
          }
          (location.pathname === "/cost-analyser" || location.pathname === "/security-vulnerability-list") ? navigate("/integrations") : location.pathname === "/guardrail" ? navigate(`${location.pathname}/add`) : location.pathname === "/all-projects" ? setOpenModel({
            modalType: "DETAILS_MODAL",
            modalProps: {
              component: <Project />,
              data: ""
            }
          }) : navigate(`${location.pathname}/create`)
        }
      }}>
        {["Security Scan"].includes(section) ? `Create` : `Create ${section}`}
      </Button>}
    </Wrapper>
  );
}
