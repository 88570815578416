import { React, useState, useEffect, useContext } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Row, Col, Tooltip } from 'antd';
import Box from '@iso/components/utility/box';
import LayoutWrapper from '@iso/components/utility/layoutWrapper';
import { SelectOption } from '@iso/components/uielements/select';
import { useLocation, useNavigate } from 'react-router-dom';
import ContentHolder from '@iso/components/utility/contentHolder';
import api from '../../../api';
import { AppContext } from '../../../context/ContextProvider';
import { AlertContext } from '../../../context/alertContext';
import awsLogo from '../../../assets/images/aws.svg';
import { marketplaceStatuses, timeSince } from '../../../context/helper';
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';

const Option = SelectOption;

const titleStyle = {
    color: "#7b7b7b",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "300",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};
const subTitleStyle = {
    color: "#1F1F1F",
    fontFamily: "Poppins",
    fontSize: "15px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

// import TableDemoStyle from './Demo.styles';
// import { tableinfos } from './configs';
const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: '220px'
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        width: '220px'
    }
]

let innerTitleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontType: "medium",
    fontWeight: "500",
    fontSize: "15px"
};

let innerSubtitleStyle = {
    fontSize: "13px",
    color: "#9B9B9B",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontType: "regular",
}

const marginsBot = {
    position: 'fixed',
    opacity: '100%',
    transition: 'all 1s ease',
    top: "7vw",
    right: "0",
    zIndex: '1000',
    width: "25%",
    padding: '15px'
};

const fadeMarginBot = {
    position: 'fixed',
    opacity: '0',
    transition: 'all 1s ease',
    top: "7vw",
    right: "0",
    zIndex: '-1',
    width: "25%",
    padding: '15px'
};

const addButtonStyle = {
    marginRight: "10px",
    fontSize: "25px"
};

const buttonCss = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 46px"
};

const footerStyle = {
    width: "100%",
    position: "absolute",
    bottom: "0",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "20px 10px",
    borderTop: "1px solid #B1B1B1",
    background: "#FFF",
}

export default ({
    data,
    columnId,
}) => {

    const { openRightDrawer, setOpenRightDrawer } = useContext(AppContext);
    const { alertOptions, set_alertOptions } = useContext(AlertContext);

    const navigate = useNavigate();
    const location = useLocation();

    const handelClick = () => {
        setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
                data: "",
            },
        });
    }

    function toggleAlert(type, message, description, hide = true, time = 5) {
        set_alertOptions(alertOptions => ({
            status: true,
            type: type,
            message: message,
            description: description,
        }));

        if (hide) {
            // opacity=0 after 10sec
            setTimeout(() => {
                set_alertOptions(alertOptions => ({
                    status: false,
                    type: type,
                    message: message,
                    description: description,
                }));
            }, time * 1000);
        }
    }


    return (
        <LayoutWrapper className="clusterInfo" style={{ padding: "0" }}>
            <CreateTaskHeader buttonContent={columnId} onCancel={handelClick} />

            <Box className="marketplaceListRightDrawer" style={{ border: 0, width: "100%" }}>
                <ContentHolder className="top-content">
                    <div className="header-card">
                        <div className="title-img">
                            <img src={awsLogo} alt="aws" style={{ width: "50px", height: "50px" }} />
                        </div>
                        <div className="header-title">
                            <div className="header-text-container">
                                <span className="header-text">{`${data.chartProvider}/${data.chartName}`}</span>
                                <div className={`header-status`} style={{ background: marketplaceStatuses[data.status]?.background }}>
                                    <span className="status-text" style={{ color: marketplaceStatuses[data.status]?.color }}>{marketplaceStatuses[data.status]?.text}</span>
                                </div>
                            </div>
                            <span className="header-subText">{data.version}</span>
                        </div>
                    </div>
                </ContentHolder>
            </Box>

            <Box
                title="App Name"
                subtitle={data.appName}
                titleStyle={innerTitleStyle}
                subTitleStyle={innerSubtitleStyle}
                style={{ border: 0, padding: "0px 60px 0px 60px", margin: 0 }}
            />
            <Box
                title="Namespace"
                subtitle={data.namespaceName}
                titleStyle={innerTitleStyle}
                subTitleStyle={innerSubtitleStyle}
                style={{ border: 0, padding: "40px 60px 0px 60px", margin: 0 }}
            />
            {/* <Box
                title="Endpoint"
                subtitle={data.endpoint ? data.endpoint : "-"}
                titleStyle={innerTitleStyle}
                subTitleStyle={innerSubtitleStyle}
                style={{ border: 0, padding: "40px 60px 0px 60px", margin: 0 }}
            />
            <Box
                title="Username"
                subtitle={data.username ? data.username : "-"}
                titleStyle={innerTitleStyle}
                subTitleStyle={innerSubtitleStyle}
                style={{ border: 0, padding: "40px 60px 0px 60px", margin: 0 }}
            />
            <Box
                title="password"
                subtitle={data.password ? data.password : "-"}
                titleStyle={innerTitleStyle}
                subTitleStyle={innerSubtitleStyle}
                style={{ border: 0, padding: "40px 60px 0px 60px", margin: 0 }}
            /> */}
            <Box
                title="Deployment Date"
                subtitle={`${timeSince(new Date(data.deployUpdatedDate))} ago`}
                titleStyle={innerTitleStyle}
                subTitleStyle={innerSubtitleStyle}
                style={{ border: 0, padding: "40px 60px 0px 60px", margin: 0 }}
            />
            <Box
                title="Version"
                subtitle={data.version}
                titleStyle={innerTitleStyle}
                subTitleStyle={innerSubtitleStyle}
                style={{ border: 0, padding: "40px 60px 0px 60px", margin: 0 }}
            />
            <Box
                title="Created By"
                subtitle={data.createdBy ? data.createdBy : "-"}
                titleStyle={innerTitleStyle}
                subTitleStyle={innerSubtitleStyle}
                style={{ border: 0, padding: "40px 60px 0px 60px", margin: 0 }}
            />
            <Box
                title="App Health"
                subtitle={marketplaceStatuses[data.appHealth]?.text}
                titleStyle={innerTitleStyle}
                subTitleStyle={{ ...innerSubtitleStyle, color: marketplaceStatuses[data.appHealth]?.color }}
                style={{ border: 0, padding: "40px 60px 50px 60px", margin: 0, marginBottom: "50px" }}
            />
        </LayoutWrapper>
    );
};