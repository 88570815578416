import { React, useState } from 'react';
// import IntlMessages from '@iso/components/utility/intlMessages';
// import Checkbox, { CheckboxGroup } from '@iso/components/uielements/checkbox';
import Box from '@iso/components/utility/box';
import ContentHolder from '@iso/components/utility/contentHolder';
// import Button from '@iso/components/uielements/button';
import { Input, Spin, Form } from 'antd';
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { useContext } from 'react';
import { AppContext } from '../../../context/ContextProvider';
import styled, { createGlobalStyle } from 'styled-components';
import Select from '../../Deployments/Deployment.styles';
import {
    SelectOption as Option,
} from '@iso/components/uielements/select';
import initialData from '../../../redux/scrumBoard/data';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { borderRadius } from '../../../library/helpers/style_utils';
import {
    PlusOutlined
} from '@ant-design/icons';
import plusIcon from '../../../assets/images/plusIcon.svg';
import { Link } from 'react-router-dom';
import CreateTaskFooter from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter';
import deleteIcon from '../../../assets/images/tagsDelete.svg';
import { Switch } from 'antd';
import downIcon from '../../../assets/images/downArrow.svg';
import upIcon from '../../../assets/images/upArrow.svg';
import api from '../../../api';
import InputWithSuffix from '../../../components/uielements/input';
import CustomInfoTooltip from '../../Dashboard/CustomComponents/CustomInfoTooltip';
import { clusterHelpChecks } from '../clusterHelpChecks';
import { LoadingOutlined } from '@ant-design/icons';

const ProdContentHold = styled.div`
  .isoBoxWrapper {
    height: auto;
    border: none;
    margin: 0;
  }
`;

var optionAzs = [];

let innerTitleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontType: "medium",
    fontWeight: "500",
    fontSize: "14px"
};

let innerSubtitleStyle = {
    fontSize: "12px",
    color: "#9b9b9b",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontType: "regular",
}

const inputStyle = {
    height: "44px"
}

export default function PglStackDrawer({
    // handleSubmit,
    data,
    setData,
    values,
    submitCount,
    onClose,
    onCancel,
    onDelete,
    openDrawer,
    refresh,
    setRefresh,
    onEditCancel,
    tabSelected,
    openAddOns
}) {

    const { openRightDrawer, setOpenRightDrawer, allClusters, setAllClusters, currentCluster, setCurrentCluster, isUpdate, updateCluster, setUpdateCluster, isOneClick, oneClickCluster, setOneClickCluster, clusterTabSelected, fetchingClusterPrerequisites, setFetchingClusterPrerequisites } = useContext(AppContext);

    const navigate = useNavigate();

    const [pglStackData, setPglStackData] = useState(isUpdate ? { ...updateCluster[clusterTabSelected].pglStack } : isOneClick ? { ...oneClickCluster[clusterTabSelected].pglStack } : { ...currentCluster[clusterTabSelected].pglStack });

    const [hostedZones, setHostedZones] = useState([]);
    const [refreshIntervalList, setRefreshIntervalList] = useState([]);
    const [objExpirationList, setObjExpirationList] = useState([]);
    const [validateDomainStatus, setValidateDomainStatus] = useState("");
    const [fetching, setFetching] = useState(false);
    const [form] = Form.useForm();
    const [dnsMsg, setDnsMsg] = useState({
        error: false,
        msg: ""
    });

    const [error, setError] = useState({
        status: false,
        msg: ""
    });

    useEffect(() => {
        setFetching(true);
        //console.log("hostedZones from currentCluster :", currentCluster[clusterTabSelected].hostedZones);
        let arr = [];
        let selectedCluster = isUpdate ? updateCluster : isOneClick ? oneClickCluster : currentCluster;

        selectedCluster[clusterTabSelected].hostedZones && Object.keys(selectedCluster[clusterTabSelected].hostedZones).map((val, id) => {
            selectedCluster[clusterTabSelected].hostedZones[val].hosted_zones.map((zone, idx) => {
                arr.push(
                    // <Option key={idx} name="hostname" data-alias={val} data-type={currentCluster[clusterTabSelected].hostedZones[val].type} value={zone}>{zone}</Option>
                    <Option key={`${idx}-${id}`} name="hostname" data-alias={val} data-type={selectedCluster[clusterTabSelected].hostedZones[val].type} value={zone}>
                        <div key={`${idx}-a`} style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                            <span key={`${idx}-b`} title={zone} style={{ flex: "-1", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                {zone}
                            </span>
                        </div>
                    </Option>
                );
            })
        });

        // arr = arr.sort((a, b) => console.log("a.props :", a.props));
        // arr = arr.sort((a, b) => a.props.value.localeCompare(b.props.value));

        // Object Expiration Dropdown
        let objExpirationDropdown = [];
        ["90"].map((val, idx) => {
            objExpirationDropdown.push(
                <Option key={idx} name="objExpiration" value={val}>{val}</Option>
            );
        });

        setObjExpirationList(objExpirationDropdown);
        setHostedZones(arr);
        setFetching(false);
    }, [currentCluster[clusterTabSelected].hostedZones, updateCluster[clusterTabSelected].hostedZones, oneClickCluster[clusterTabSelected].hostedZones]);


    useEffect(() => {
        // if(!isUpdate) {
            let myTimeout = setTimeout(() => {
                console.log("My PGL: ", (pglStackData.hostname, updateCluster.selectedCluster.addons_list.find(val => val.name === "PGL stack")?.value?.hostname));
                if ((pglStackData.recordPrefix && pglStackData.recordPrefix !== "") /*&& !isUpdate*/ && ((!isUpdate && !isOneClick) || (isOneClick) || (isUpdate && pglStackData.hostname !== updateCluster.selectedCluster.addons_list.find(val => val.name === "PGL stack")?.value?.host_name))) {
                    let selectedCluster = isUpdate ? updateCluster : isOneClick ? oneClickCluster : currentCluster;
                    let checkFor = selectedCluster[clusterTabSelected].selectedAddons.find(val => val.name === "PGL stack").id;
                    dnsCheck(pglStackData.recordPrefix, pglStackData.hostname, pglStackData.zoneId, pglStackData.dnsRecordType, checkFor);
                }
            }, 2000);
    
            return () => clearTimeout(myTimeout);    
        // }
    }, [pglStackData.recordPrefix, pglStackData.hostname]);

    const handleSelectChange = (value, event) => {
        console.log("event :", event);
        console.log("value :", value);

        if (event.name === "hostname") {
            pglStackData.zoneId = event["data-alias"];
            pglStackData.dnsRecordType = event["data-type"];
            pglStackData[event.name] = value;

            setDnsMsg({
                error: false,
                msg: ""
            });
        }
        else {
            pglStackData[event.name] = value;
        }

        console.log("pglStackData :", pglStackData);
        setPglStackData({ ...pglStackData });
        setError({
            status: false,
            msg: ""
        });
    };

    const handelInputChange = (e) => {
        setPglStackData({ ...pglStackData, [e.target.name]: e.target.value });
        if (e.target.name === "recordPrefix") {
            setDnsMsg({
                error: false,
                msg: ""
            });
        }
        setError({
            status: false,
            msg: ""
        });
    };

    const dnsCheck = (recordPrefix, zoneName, zoneId, dnsRecordType, checkFor) => {
        setValidateDomainStatus("validating");
        api.post('/api/cluster/check-dns/', [{
            "record_name": recordPrefix,
            "zone_id": zoneId,
            "dns_record_type": dnsRecordType,
            "zone_name": zoneName,
            "check_for": checkFor
        }]).then((res) => {
            let data = res.data;
            if (data.status) {
                setDnsMsg({
                    error: false,
                    msg: Object.values(data.data)[0]
                });
                setValidateDomainStatus("success");
            }
            else {
                setDnsMsg({
                    error: true,
                    msg: Object.values(data.error_details)[0]
                });
                setValidateDomainStatus("error");
            }
        }).catch((err) => {
            console.log("Error");
            setValidateDomainStatus("error");
        });
    }

    const handleCancel = (e) => {
        setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
                pglStackData: "",
            },
        });

        // onClose();
    };

    const handleSaveDetails = async (e) => {
        try {
            const values = await form.validateFields();
            console.log('Success:', values);
            console.log('pglStackData :', pglStackData);
            let selectedCluster = isUpdate ? updateCluster : isOneClick ? oneClickCluster : currentCluster;
            selectedCluster[clusterTabSelected].pglStack = pglStackData;
            for (let i = 0; i < selectedCluster[clusterTabSelected].selectedAddons.length; i++) {
                if (selectedCluster[clusterTabSelected].selectedAddons[i].name === "PGL stack") {
                    if (dnsMsg.error) {
                        selectedCluster[clusterTabSelected].selectedAddons[i] = {
                            ...selectedCluster[clusterTabSelected].selectedAddons[i],
                            message: dnsMsg.msg,
                            borderColor: "red"
                        };
                        break;
                    }
                    else {
                        selectedCluster[clusterTabSelected].selectedAddons[i] = {
                            ...selectedCluster[clusterTabSelected].selectedAddons[i],
                            message: "",
                            borderColor: "#f5f5f5"
                        };
                        break;
                    }
                }
            }
            if (isUpdate) {
                setUpdateCluster({
                    ...updateCluster,
                    clusterTabSelected: updateCluster[clusterTabSelected]
                });
                localStorage.setItem('updateCluster', JSON.stringify(({
                    ...updateCluster,
                    clusterTabSelected: updateCluster[clusterTabSelected]
                })));
            }
            else if (isOneClick) {
                setOneClickCluster({
                    ...oneClickCluster,
                    clusterTabSelected: oneClickCluster[clusterTabSelected]
                });
                localStorage.setItem('oneClickCluster', JSON.stringify({
                    ...oneClickCluster,
                    clusterTabSelected: oneClickCluster[clusterTabSelected]
                }));
            }
            else {
                setCurrentCluster({
                    ...currentCluster,
                    clusterTabSelected: currentCluster[clusterTabSelected]
                });
                localStorage.setItem('Cluster', JSON.stringify({
                    ...currentCluster,
                    clusterTabSelected: currentCluster[clusterTabSelected]
                }));
            }

            setRefresh(refresh => !refresh);

            setOpenRightDrawer({
                drawerType: false,
                drawerProps: {
                    pglStackData: "",
                },
            });
        }
        catch (err) {
            console.log("Error :", err);
        }
    };

    const selectHostedZones = () => {

    }

    //   const filterClusterLogOption = (input, option) => (option?.value.toLowerCase() ?? '').includes(input.toLowerCase());

    //   const filterVPCLogOption = (input, option) => (option?.value.toLowerCase() ?? '').includes(input.toLowerCase());


    return (
        <ProdContentHold>
            <CreateTaskHeader
                buttonContent="PGL Stack"
                data={pglStackData}
                // values={{editing: true}}
                onCancel={() => {
                    setOpenRightDrawer({
                        drawerType: false,
                        drawerProps: {
                            pglStackData: "",
                        },
                    });
                    // onClose();
                }}
                onDelete={onDelete}
                onEditCancel={() => {
                    setOpenRightDrawer({
                        drawerType: false,
                        drawerProps: {
                            pglStackData: "",
                        },
                    });
                    // onClose();
                }}
            />

            <Form
                form={form}
                initialValues={{ ...pglStackData }}
                preserve={false}
                name="dynamic_rule"
                // disabled={isUpdate}
            // style={{
            //     maxWidth: 600,
            // }}
            >
                <Box
                    title={"Appname*"}
                    subtitle={"Enter the name of the stack."}
                    titleStyle={innerTitleStyle}
                    subTitleStyle={innerSubtitleStyle}
                    style={{ marginTop: "80px" }}
                >
                    <ContentHolder>
                        <Form.Item
                            name="appName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please provide App Name.',
                                },
                                {
                                    // Custom validator for special characters
                                    validator: (_, value) => {
                                        const specialCharPattern = /^([a-z0-9]+-*)+$/; // This regex allows only letters, numbers, and spaces
                                        if (!value || specialCharPattern.test(value)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('App name should start with alphanumeric char and can contain numbers, lowercase letters and hyphens only.'));
                                    }
                                },
                                {
                                    // Custom validator for special characters
                                    validator: (_, value) => {
                                        if (value && value.length <= 15) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('App name should not contain more than 15 characters.'));
                                    }
                                },
                            ]}
                        >
                            <Input
                                name="appName"
                                value={pglStackData.appName}
                                style={{ ...inputStyle }}
                                onChange={handelInputChange}
                                placeholder="Name"
                            />
                        </Form.Item>
                    </ContentHolder>
                </Box>

                <Box
                    title={<div style={{ display: "flex", justifyContent: "space-between" }}>
                        <p>Hostname* {/*<Tooltip title="Can be increased but not decreased"><InfoCircleFilled style={{ marginLeft: "10px" }} /></Tooltip>*/}</p>
                        <CustomInfoTooltip title={clusterHelpChecks.pglAddon.hostName} placement={"bottomLeft"} />
                    </div>} subtitle={"Enter the hostname for the stack"}
                    titleStyle={innerTitleStyle}
                    subTitleStyle={innerSubtitleStyle}
                >
                    <ContentHolder>
                        {console.log("Validate domain status: ", validateDomainStatus)}
                        <Form.Item
                            name="recordPrefix"
                            //validateStatus={validateDomainStatus}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please provide hostname prefix.',
                                },
                                {
                                    // Custom validator for special characters
                                    validator: (_, value) => {
                                        const specialCharPattern = /^([a-z0-9]+\.*-*)+$/; // This regex allows only letters, numbers, and spaces
                                        console.log("currentCluster[clusterTabSelected].k8sDashboard.recordPrefix :", currentCluster[clusterTabSelected].k8sDashboard.recordPrefix);
                                        console.log("value :", value);
                                        if (value === currentCluster[clusterTabSelected].k8sDashboard.recordPrefix) {
                                            return Promise.reject(new Error('Hostname should not be same as K8s Dashboard hostname.'));
                                        }
                                        else if (!value || specialCharPattern.test(value)) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error('Hostname should start with alphanumeric char and can contain numbers, lowercase letters, hyphens or full stops.'));
                                    }
                                }
                            ]}
                        >
                            {console.log("Validate status: ", validateDomainStatus)}
                            <InputWithSuffix
                                name="recordPrefix"
                                className='inputAddonAfter'
                                value={pglStackData.recordPrefix}
                                // styles={{...inputStyle}}
                                style={{ height: "44px !important", zIndex: 1 }}
                                onChange={handelInputChange}
                                placeholder="hostname"
                                suffix={validateDomainStatus === "validating" ? <LoadingOutlined /> : <></>}
                                addonAfter={
                                    <Select
                                        defaultValue={pglStackData.hostname}
                                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                        onChange={handleSelectChange}
                                        border={0}
                                        className="cusIntSelectDrop"
                                        style={{ padding: "0", borderRadius: "6px !important", zIndex: 1 }}
                                    >
                                        {fetching
                                            ?
                                            <Option>
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px" }}>
                                                    <Spin size="small" />
                                                </div>
                                            </Option>
                                            :
                                            hostedZones
                                        }
                                    </Select>
                                }
                            />
                        </Form.Item>
                        <p style={{ color: dnsMsg.error ? "red" : "green", transform: "translateY(-20px)" }}> {dnsMsg.msg} </p>
                    </ContentHolder>
                </Box>

                <Box
                    title={"Loki*"}
                    subtitle={"Toggle on to enable Loki logging."}
                    titleStyle={innerTitleStyle}
                    subTitleStyle={innerSubtitleStyle}
                    style={{ position: "relative" }}
                >
                    <ContentHolder style={{ position: "absolute", top: "0", right: "0", marginRight: "25px" }}>
                        <Switch style={pglStackData.loki ? { backgroundColor: "#132f44" } : {}} checked={pglStackData.loki} onChange={(checked) => {
                            setPglStackData({ ...pglStackData, loki: checked });
                            setError({
                                status: false,
                                msg: ""
                            });
                        }} />
                    </ContentHolder>
                </Box>

                <Box
                    title={<div style={{ display: "flex" }}>
                        <p>Cloud watch collector* {/*<Tooltip title="Can be increased but not decreased"><InfoCircleFilled style={{ marginLeft: "10px" }} /></Tooltip>*/}</p>
                        <CustomInfoTooltip title={clusterHelpChecks.pglAddon.cloudWatchCollector} placement={"bottomLeft"} style={{ marginLeft: "10px" }} />
                    </div>} subtitle={"Toggle on to enable the CloudWatch collector for monitoring."}
                    titleStyle={innerTitleStyle}
                    subTitleStyle={innerSubtitleStyle}
                    style={{ position: "relative" }}
                >
                    <ContentHolder style={{ position: "absolute", top: "0", right: "0", marginRight: "25px" }}>
                        <Switch style={pglStackData.cloudWatchCollector ? { backgroundColor: "#132f44" } : {}} checked={pglStackData.cloudWatchCollector} onChange={(checked) => {
                            setPglStackData({ ...pglStackData, cloudWatchCollector: checked });
                            setError({
                                status: false,
                                msg: ""
                            });
                        }} />
                    </ContentHolder>
                </Box>

                <Box
                    title={<div style={{ display: "flex" }}>
                        <p>Dashboard Refresh Interval* {/*<Tooltip title="Can be increased but not decreased"><InfoCircleFilled style={{ marginLeft: "10px" }} /></Tooltip>*/}</p>
                        <CustomInfoTooltip title={clusterHelpChecks.pglAddon.dashboardRefreshInterval} placement={"bottomLeft"} style={{ marginLeft: "10px" }} />
                    </div>} subtitle={"Specify the interval for dashboard refresh in seconds"}
                    titleStyle={innerTitleStyle}
                    subTitleStyle={innerSubtitleStyle}
                >
                    <ContentHolder>
                        <Input
                            name="refreshInterval"
                            value={pglStackData.refreshInterval}
                            style={{ ...inputStyle, cursor: "not-allowed" }}
                            onChange={handelInputChange}
                            placeholder="Name"
                            disabled={true}
                        />
                    </ContentHolder>
                </Box>

                {/* <Box
                    title={<div style={{ display: "flex", justifyContent: "space-between" }}>
                        <p>Object Expiration*</p>
                        <CustomInfoTooltip title={clusterHelpChecks.pglAddon.objectExpiration} placement={"bottomLeft"} />
                    </div>} subtitle={"Select the number of days after which S3 objects should expire."}
                    titleStyle={innerTitleStyle}
                    subTitleStyle={innerSubtitleStyle}
                >
                    <ContentHolder>
                        <Select
                            defaultValue={pglStackData.objExpiration}
                            getPopupContainer={(triggerNode) => triggerNode.parentNode}
                            onChange={handleSelectChange}
                            style={{ width: "100%" }}
                        >
                            {objExpirationList}
                        </Select>
                    </ContentHolder>
                </Box> */}

                <Box style={{ height: "150px" }}>
                    <Box>
                    </Box>
                </Box>
            </Form>
            <CreateTaskFooter buttonContent={"PGL Stack"} givenButtonFunction1={handleSaveDetails} givenButtonFunction2={handleCancel} openDrawer={openDrawer} />
        </ProdContentHold>
    );
};
