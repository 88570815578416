import styled from 'styled-components';
import { Dropdown, Form, Input } from 'antd';
import WithDirection from '@iso/lib/helpers/rtl';
import InputBox from '../Ecommerce/Checkout/InputBox';

const WDInput = styled(Input)`
    .ant-input-group-addon {
        height: 44px;
        color: #9b9b9b;
    }
    // .ant-input {
    //   height: 44px;
    //   border-radius: 8px !important;
    // }
    .ant-input-wrapper {
        .ant-input {
            height: 44px;
        }
    }
    .ant-input-affix-wrapper {
        height: 0;
        .ant-input {
            height: 42px;
        }
    }
`;

export const FormWrapper = styled(Form)`
.ant-form-item-has-warning .ant-input,
.ant-form-item-has-warning .ant-select-selector,
.ant-form-item-has-warning .ant-picker-input,
.ant-form-item-has-warning .ant-checkbox-wrapper,
.ant-form-item-has-warning .ant-radio-wrapper {
  border: 1.5px solid #4c99ef !important;
}

.ant-form-item-explain-warning {
    color: #4c99ef;
  }
    .ant-input-status-warning {
      border: 1.5px solid #4c99ef !important;
    }
  .ant-input-affix-wrapper-status-warning {
    border: 1.5px solid #4c99ef !important;
  }
  .ant-input-affix-wrapper {
    .ant-input {
    border: 0 !important;
    }
  }

  .ant-input-suffix {
    color: #000000 !important;
  }

`;

//const isoInput = WithDirection(WDInput);
//console.log("iso", isoInput);

//const SelectOption = Select.Option;


export default WDInput;