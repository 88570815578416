import { React, useState, useEffect, useContext } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Row, Col, Tooltip, Form } from 'antd';
import Box from '@iso/components/utility/box';
import LayoutWrapper from '@iso/components/utility/layoutWrapper';
import { SelectOption } from '@iso/components/uielements/select';
import { useLocation, useNavigate } from 'react-router-dom';
import ContentHolder from '@iso/components/utility/contentHolder';
import parse from "html-react-parser";
import api from '../../../api';
import { AppContext } from '../../../context/ContextProvider';
import { AlertContext } from '../../../context/alertContext';
import customScriptIcon from '@iso/assets/images/custom-script-icon.svg';
import blueTriangleWorkflow from '@iso/assets/images/blueTriangleWorkflow.svg';
import dragIcon from '@iso/assets/images/drag-icon.svg';
import awsLogo from '../../../assets/images/aws.svg';
import { marketplaceStatuses, timeSince } from '../../../context/helper';
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { StickerWidgetWrapper } from '../../Widgets/SmallCards/SmallCards.styles';
import plusIcon from '@iso/assets/images/plusIcon.svg';
import ScrollableFeed from 'react-scrollable-feed';
import CodeViewGeneral from '../../Clusters/Task/CodeViewGeneral';
import CreateTaskFooter from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter';
import Input from '@iso/containers/Deployments/ApplicationBasicDetails.styles';
import Select from '@iso/containers/Deployments/Deployment.styles';
import Scrollbars from 'react-custom-scrollbars-2';

const Option = SelectOption;

let innerTitleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "12px"
};

let innerSubtitleStyle = {
    fontSize: "11px",
    color: "#9B9B9B",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
}

const blueTriangleStyle = {
    position: "absolute",
    right: "2.5%",
    top: "40%",
}

const widgetStyle = {
    display: "block",
    margin: "40px 20px",
    width: "inherit",
};

let boxTitleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontType: "medium",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "1px",
};

let boxSubtitleStyle = {
    fontSize: "12px",
    lineHeight: "20px",
    color: "#9b9b9b",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontType: "regular",
}

export default ({
    data,
    openDrawer,
    columnId,
}) => {

    const { openRightDrawer, setOpenRightDrawer, workflowData, setWorkflowData, currentWorkflowData, setCurrentWorkflowData } = useContext(AppContext);
    const { alertOptions, set_alertOptions } = useContext(AlertContext);

    const navigate = useNavigate();
    const location = useLocation();
    const [form] = Form.useForm();

    const [customScriptData, setCustomScriptData] = useState({
        cpu: "100",
        ram: "500",
        volume: "10",
    });

    const [script, setScript] = useState("");
    const [error, setError] = useState(false);
    const [volumeList, setVolumeList] = useState([]);

    // set dropdown for platform type and volume
    useEffect(() => {
        let tempVolume = [];

        [10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500].map((child, ind) => {
            tempVolume.push(
                <Option key={child + ind + '!'} name="volume" value={child}>
                    <div style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: "center", paddingTop: "0px" }}>
                        <p> {child} </p>
                    </div>
                </Option>
            );
        });

        setVolumeList(tempVolume);
    }, []);

    useEffect(() => {
        console.log("currentWorkflowData in buildClickRightDrawer :", currentWorkflowData);

        let tempData = {
            cpu: currentWorkflowData.buildConfig.customScriptConfig?.config?.cpu ? currentWorkflowData.buildConfig.customScriptConfig.config.cpu : "100",
            ram: currentWorkflowData.buildConfig.customScriptConfig?.config?.ram ? currentWorkflowData.buildConfig.customScriptConfig.config.ram : "500",
            volume: currentWorkflowData.buildConfig.customScriptConfig?.config?.volume ? currentWorkflowData.buildConfig.customScriptConfig.config.volume : "10",
        }

        setCustomScriptData(tempData);

        form.setFieldsValue({
            cpu: currentWorkflowData.buildConfig.customScriptConfig?.config?.cpu ? currentWorkflowData.buildConfig.customScriptConfig.config.cpu : "100",
            ram: currentWorkflowData.buildConfig.customScriptConfig?.config?.ram ? currentWorkflowData.buildConfig.customScriptConfig.config.ram : "500",
            volume: currentWorkflowData.buildConfig.customScriptConfig?.config?.volume ? currentWorkflowData.buildConfig.customScriptConfig.config.volume : "10",
        });
    }, []);

    useEffect(() => {
        console.log("inside customScript raw :", currentWorkflowData.buildConfig.customScriptConfig.name);
        if (currentWorkflowData?.buildConfig?.customScriptConfig?.name) {
            let str = currentWorkflowData.buildConfig.customScriptConfig.config.script;
            try {
                let newStr = JSON.parse(str);
                if (newStr.startsWith('\"') && newStr.endsWith('\"')) {
                    newStr = newStr.replace(/\\n/g, "\n").replace(/\\"/g, '"');
                    newStr = newStr.slice(1, -1);
                }
                console.log("New parsed script :", newStr);
                setScript(newStr);
            } catch (err) {
                console.log("err :", err);
                console.log("using script as is...");
                setScript(currentWorkflowData.buildConfig.customScriptConfig.config.script);
            }
        }
    }, []);

    const handelClick = () => {
        setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
                data: "",
            },
        });
    }

    const handleSaveDetails = (e) => {
        console.log("clicked handleSaveDetails and script is :", script);
        if (!script) {
            setError(true);
            return;
        }

        setWorkflowData((prevData) => ({
            ...prevData,
        }));

        let obj = {
            name: "post_build_script",
            enabled: true,
            config: {
                agent: "ubuntu:20.04",
                script: script,
                cpu: customScriptData.cpu,
                ram: customScriptData.ram,
                volume: customScriptData.volume,
                // script: "#!/bin/bash\r\n\r\n ls -la;# Count the number of environment variables\r\ncount=$(printenv | wc -l)\r\n\r\n# Check if the count is greater than 10\r\nif [ \"$count\" -gt 10 ]; then\r\n    echo \"More than 10 variables are located\" \r\nelse\r\n    echo \"10 or fewer variables are located\"\r\nfi\r\n"
            }
        }

        currentWorkflowData.buildConfig.customScriptConfig = obj;
        setCurrentWorkflowData({ ...currentWorkflowData });
        localStorage.setItem("Workflow", JSON.stringify({ ...currentWorkflowData }));
        handelClick();
    }

    const handleCancel = (e) => {
        console.log("clicked handleCancel...");
        handelClick();
    }

    const handleDragTools = (e) => {
        console.log("clicked handleDragTools :", e.target);
        console.log("clicked handleDragTools e.target.id :", e.target.id);

        if (e.target?.id) {
            // setToolSelected(parseInt(e.target.id));
            // setAllTools([...allTools]);
        }
    }

    const handelInputChange = (e) => {
        console.log("inside handelInputChange event :", e);
        let name = e.target.name;
        let value = e.target.value;

        if (['cpu', 'ram'].includes(name)) {
            setCustomScriptData({ ...customScriptData, [name]: value });
        }
        else {
            setCustomScriptData({ ...customScriptData, [name]: value });
        }
    }

    const handleSelectChanges = (value, event) => {
        // integration-redirect
        console.log("inside handleSelectChanges event :", event);
        console.log("inside handleSelectChanges value :", value);
        setCustomScriptData({ ...customScriptData, [event.name]: event.value });
    }

    return (
        <LayoutWrapper className="clusterInfo" style={{ padding: "0", height: "100%" }}>
            <CreateTaskHeader buttonContent={columnId} onCancel={handelClick} />

            <div style={{ marginTop: "84px", width: "100%" }} />

            <Col lg={8} md={8} sm={8} xs={8} style={{ borderRight: "1px solid #ebebeb", height: "inherit" }}>
                <Box
                    title="Configure tools for pipeline"
                    subtitle="Drag and drop to add tools in the flow"
                    titleStyle={{ ...innerTitleStyle, lineHeight: "20px" }}
                    subTitleStyle={innerSubtitleStyle}
                    style={{ border: 0, padding: "0px 20px", margin: 0, marginTop: "40px", height: "fit-content" }}
                />

                <div style={{ position: "relative" }}>
                    <StickerWidgetWrapper className="isoStickerWidget" style={{ marginTop: "16px", marginLeft: "22px", width: "250px", position: "relative" }}>
                        <Box style={{ borderRadius: "10px", cursor: "pointer", padding: "0", position: "relative" }}>
                            <ContentHolder
                                style={{ display: "flex", alignItems: "start", cursor: "pointer", marginTop: "0", position: "relative", padding: "18px" }}
                            >
                                <Box
                                    title={
                                        <div style={{ display: "flex", justifyContent: "start", alignItems: "center", position: "relative", paddingRight: "55px" }}>
                                            <span style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "100%", width: "36px", height: "36px", background: "white", padding: "3px", boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.25)" }}>
                                                <img src={customScriptIcon} />
                                            </span>
                                            <div>
                                                <p style={{ color: "#1F1F1F", marginLeft: "10px", fontSize: "12px", lineHeight: "20px", fontFamily: "Poppins" }}>Custom Script</p>
                                                <p style={{ marginLeft: "10px", ...innerSubtitleStyle }}>Click to configuration</p>
                                            </div>
                                        </div>
                                    }
                                    // subtitle={<p style={{ lineHeight: "24px", color: "#9B9B9B" }}>Click to configuration</p>}
                                    style={{ border: "transparent", padding: "0", marginBottom: "0", position: "relative" }}
                                    titleStyle={{ ...innerTitleStyle, marginBottom: "0", position: "relative" }}
                                    subTitleStyle={innerSubtitleStyle}
                                />
                            </ContentHolder>
                        </Box>
                    </StickerWidgetWrapper>
                    {/* <span style={blueTriangleStyle} /> */}
                    <img src={blueTriangleWorkflow} style={blueTriangleStyle} />
                    <img src={dragIcon} alt="drag" onClick={handleDragTools} style={{ position: "absolute", top: "29px", left: "13px", width: "18px", height: "22px", objectFit: "cover", cursor: "pointer" }} />
                </div>
            </Col>

            <Col lg={16} md={16} sm={16} xs={16} style={{ }}>
                <Scrollbars
                    className='notificationsScroll'
                    style={{ height: "100%" }} // 400px
                    // onScroll={handleScroll}
                >
                    <StickerWidgetWrapper className="isoStickerWidget" style={{ ...widgetStyle }}>
                        <Form
                            form={form}
                            initialValues={{ ...customScriptData }}
                            preserve={false}
                            name="dynamic_rule"
                            style={{
                                width: "100%",
                            }}
                        >
                            <Box
                                title={"Compute Configuration*"}
                                subtitle={"Select compute type from the following."}
                                titleStyle={boxTitleStyle}
                                subTitleStyle={boxSubtitleStyle}
                                style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content" }}
                            >
                                <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                                    <Form.Item
                                        name="cpu"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please provide cpu.',
                                            },
                                            {
                                                validator: (_, value) => {
                                                    if (value >= 100) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('The number must be at least 100!'));
                                                }
                                            },
                                            {
                                                pattern: /^[1-9]\d*$/, // Regular expression for positive integers
                                                message: 'Please provide a positive integer.',
                                            },
                                        ]}
                                    >
                                        <div style={{ height: "44px" }}>
                                            <Input className="adjust-height" placeholder="CPU" style={{ height: "44px" }} suffix={"m"} value={customScriptData?.cpu} disabled={currentWorkflowData?.fromPipeline} name="cpu" onChange={handelInputChange} />
                                        </div>
                                    </Form.Item>
                                </ContentHolder>
                            </Box>

                            <Box
                                title={""}
                                subtitle={""}
                                titleStyle={boxTitleStyle}
                                subTitleStyle={boxSubtitleStyle}
                                style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content" }}
                            >
                                <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                                    <Form.Item
                                        name="ram"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please provide ram.',
                                            },
                                            {
                                                validator: (_, value) => {
                                                    if (value >= 100) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('The number must be at least 100!'));
                                                }
                                            },
                                            {
                                                pattern: /^[1-9]\d*$/, // Regular expression for positive integers
                                                message: 'Please provide a positive integer.',
                                            },
                                        ]}
                                    >
                                        <div style={{ height: "44px" }}>
                                            <Input className="adjust-height" placeholder="RAM" style={{ height: "44px" }} suffix={"Mi"} value={customScriptData?.ram} disabled={currentWorkflowData?.fromPipeline} name="ram" onChange={handelInputChange} />
                                        </div>
                                    </Form.Item>
                                </ContentHolder>
                            </Box>

                            <Box
                                title={"Volume*"}
                                subtitle={"Select volume from the following. Volume will be in Gi."}
                                titleStyle={boxTitleStyle}
                                subTitleStyle={boxSubtitleStyle}
                                style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content" }}
                            >
                                <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                                    <Form.Item
                                        name="volume"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select volume.',
                                            },
                                        ]}
                                    >
                                        <Select
                                            value={customScriptData?.volume ? customScriptData.volume : undefined}
                                            placeholder="Select"
                                            onChange={handleSelectChanges}
                                            style={{ width: "100%" }}
                                            className="centricText"
                                            disabled={currentWorkflowData?.fromPipeline}
                                        >
                                            {volumeList}
                                        </Select>
                                    </Form.Item>
                                </ContentHolder>
                            </Box>
                        </Form>
                        <Box
                            title={"Script*"}
                            subtitle={error && <p className="isoHelperText labels">Post-Build script can't be empty</p>}
                            titleStyle={boxTitleStyle}
                            subTitleStyle={boxSubtitleStyle}
                            style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content", marginBottom: "150px" }}
                        >
                            <CodeViewGeneral data={script} setData={setScript} setError={setError} height="calc(100vh - 300px)" minHeight="370px" type="script" readOnly={currentWorkflowData.fromPipeline ? true : false} />
                        </Box>
                    </StickerWidgetWrapper>
                </Scrollbars>
            </Col>

            <CreateTaskFooter buttonContent={"Custom Script"} givenButtonFunction1={handleSaveDetails} givenButtonFunction2={handleCancel} openDrawer={openDrawer} />

        </LayoutWrapper>
    );
};